import React from "react";

type ButtonProps = {
  label: string;
  type?: "button" | "submit" | undefined;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
};

export const OnboardingButton = ({
  type = "button",
  label,
  disabled,
  onClick,
  className = "pt-12",
}: ButtonProps) => {
  const disableClass = disabled
    ? "bg-white text-grey border border-grey cursor-not-allowed"
    : "bg-green text-white hover:bg-light-green";

  return (
    <div className={`${className} px-5 fixed sm:px-0 sm:relative bottom-5 max-w-md w-full`}>
      <button
        tabIndex={0}
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={`${disableClass} rounded-lg whitespace-nowrap w-full px-8 py-4 text-lg font-medium duration-[80ms] transition-colors shadow-md sm:shadow-none`}
      >
        {label}
      </button>
    </div>
  );
};

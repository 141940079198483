import { useMemo, useState } from "react";
//@ts-ignore
import { getCountriesForTimezone } from "countries-and-timezones";
import { isBrowser } from "../../utils/ssr";

const useGeoCountryCode = () => {
  const [countryCode, setCountryCode] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState(undefined);

  if (!isBrowser()) {
    return { data: countryCode, error: errorMessage };
  }

  useMemo(async () => {
    try {
      const res = await fetch("https://ipapi.co/json/");
      if (!res.ok) {
        throw Error(res.statusText);
      }
      const data = await res.json();
      if (data.country_code) {
        setCountryCode(data.country_code);
      }
    } catch (error: any) {
      console.log("Couldn't get geoloaction from ipapi: ", error);
      setErrorMessage(error);
      try {
        setErrorMessage(undefined);
        const res = await fetch(`${process.env.GATSBY_FIREBASE_CLOUD_FUNCTION_URL}/geolocation`);
        if (!res.ok) {
          throw Error(res.statusText);
        }
        const data = await res.json();
        if (data.country) {
          setCountryCode(data.country);
        }
      } catch (error: any) {
        console.log("Couldn't get geoloaction from cloud function: ", error);
        setErrorMessage(error);
        try {
          setErrorMessage(undefined);
          setCountryCode(getCountriesForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)[0].id);
        } catch (error: any) {
          console.log("Couldn't get geoloaction from browser timezone: ", error);
          setErrorMessage(error);
        }
      }
    }
  }, [setCountryCode, setErrorMessage]);

  return { data: countryCode, error: errorMessage };
};
export default useGeoCountryCode;

import React, { ChangeEvent, MutableRefObject } from "react";

type InputFieldProps = {
  placeholderText?: string;
  inputType?: string;
  value?: string;
  focus?: boolean;
  min?: number;
  max?: number;
  maxLength?: number;
  pattern?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  ref?: MutableRefObject<HTMLInputElement>;
  unit?: string;
};

export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  (
    { inputType = "number", value, placeholderText, maxLength, focus, min, max, pattern, onChange, unit },
    ref
  ) => (
    <div className="relative">
      <input
        ref={ref}
        className="bg-white px-5 py-5 w-full rounded-lg shadow-md label-xl text-center"
        type={inputType}
        value={value}
        autoFocus={focus}
        min={min}
        max={max}
        maxLength={maxLength}
        pattern={pattern}
        placeholder={placeholderText}
        onChange={onChange}
      />
      {unit && <span className="-ml-[1.875rem]">{unit}</span>}
    </div>
  )
);

InputField.displayName = "InputField";

import { useMutation } from "@apollo/client";
import { getFirestore } from "firebase/firestore";
import { useCallback, useState } from "react";
import UpdateUser from "../../../graphql/UpdateUser.graphql";
import { useStateWithLocalStorage } from "../../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";
import firebaseApp from "../../../utils/firebase";
import { sendEvent } from "../../../utils/gtm";
import { encodeUserIdForFirestore } from "../../../utils/utils";
import { setCurrentWeight } from "../setCurrentWeight";
import { setGoal } from "../setGoal";
import { setWeightGoal } from "../setGoalWeight";

export function useOnboardingStore() {
  const [weightGoal] = useStateWithLocalStorage<number>("dd/onboarding/weight-goal", -1);
  const [currentWeight] = useStateWithLocalStorage<number>("dd/onboarding/current-weight", -1);
  const [onboardingPayload] = useStateWithLocalStorage<object>("dd/onboarding/payload", {});
  const [onboardingGoal] = useStateWithLocalStorage<string[]>("dd/onboarding/goal", []);

  const [updateUser] = useMutation(UpdateUser);
  const [data, setData] = useState(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  const update = useCallback(
    async ({ firebaseUserId }: { firebaseUserId: string }) => {
      setError(false);
      setSaving(true);
      const db = getFirestore(firebaseApp);
      const userId = firebaseUserId && encodeUserIdForFirestore(firebaseUserId);
      const date = new Date();

      try {
        await setCurrentWeight(db, userId!, date, currentWeight);
        await setWeightGoal(db, userId!, date, weightGoal);
        await setGoal(db, userId!, date, onboardingGoal);

        const { data: onBoardingData } = await updateUser({
          variables: {
            input: onboardingPayload,
          },
        });

        setData(onBoardingData);

        if (onBoardingData) {
          sendEvent({
            eventName: "onboarding",
            action: "successfully_completed_onboarding",
            payload: {
              onBoardingData: onBoardingData.updateUser,
            },
          });
        }
      } catch (error) {
        console.log({ error });

        setError(true);

        sendEvent({
          eventName: "onboarding",
          action: "failed_to_submit_onboarding",
          payload: {
            onBoardingData: null,
          },
        });
      } finally {
        setSaving(false);
      }
    },
    [currentWeight, onboardingGoal, onboardingPayload, updateUser, weightGoal]
  );

  return { data, update, saving, error };
}

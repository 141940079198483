import { RouteComponentProps } from "@reach/router";
import { useActor } from "@xstate/react";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext, useEffect } from "react";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useOnboardingTranslations } from "../translations";
import { OnboardingStep } from "../types";
import { navigateToNextStep } from "../utils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function StepFiveCompletionSplash(_: RouteComponentProps) {
  const tt = useOnboardingTranslations();

  const onboarding = useContext(OnboardingStateContext);
  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.STEP_FIVE_COMPLETION });
  }, [send]);

  return (
    <div className="">
      <div className="flex flex-col items-center">
        <OnboardingTitle title="Our approach helps you feel fuller for longer" className="mb-5" />

        <StaticImage
          src="../../../../static/images/onboarding-AB-test/pizza-hamburger.jpg"
          placeholder="none"
          height={320}
          objectFit="contain"
          alt="pizza and hamburger"
          className="max-w-lg rounded-xl"
          loading="eager"
        />

        <p className="m-0 text-lg text-center mt-7 max-w-md">
          You’ll enjoy delicious and satisfying food — so it doesn’t feel like a diet and is easier to stick to.
        </p>
        <p className="m-0 text-lg text-center mt-5 max-w-md">
          Our programs and recipes are developed by our registered dietitians and medical team.
        </p>
        <p className="m-0 text-lg text-center mt-5 max-w-md">
          Establish healthy habits and learn what foods are best for you.
        </p>
        <OnboardingButton
          label={tt.buttonContinue}
          onClick={() => navigateToNextStep(OnboardingStep.STEP_FIVE_COMPLETION)}
        />
      </div>
    </div>
  );
}

import { useActor, useSelector } from "@xstate/react";
import React, { useContext, useEffect } from "react";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { SingleChoiceButton } from "../components/SingleChoiceButtton";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { MealFrequency, OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { navigateToNextStep } from "../utils";

export const mealPerDaySelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.dailyMealsCount;

export function MealsPerDayStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const mealsPerDay = useSelector(onboarding.machine!, mealPerDaySelector);
  const tt = useOnboardingTranslations();
  const mealsPerDayOptions = getOptions(tt);

  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.MEAL_PER_DAY });
  }, [send]);

  const handleChange = (value: MealFrequency) => {
    send({ type: "SET_PAYLOAD", payload: "dailyMealsCount", value: Number(value) });
    if (onDone) {
      onDone?.();
    } else {
      navigateToNextStep(OnboardingStep.MEAL_PER_DAY);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.mealsPerDayStep.question} />

      {mealsPerDayOptions.map(({ option, label, explanation, subDetail }) => (
        <SingleChoiceButton
          key={option}
          value={option}
          textLabel={label}
          name="dailyMealsCount"
          explanation={explanation}
          subDetail={subDetail}
          onChange={(event) => handleChange(event.target.value as MealFrequency)}
          isActive={mealsPerDay?.toString() === option}
        />
      ))}
    </div>
  );
}

export function getOptions(
  tt: OnboardingTranslation
): Array<{ option: MealFrequency; label: string; explanation?: string; subDetail?: string }> {
  return [
    {
      option: "3",
      label: tt.mealsPerDayStep.answerThree,
      explanation: tt.mealsPerDayStep.answerThreeExplanation,
    },
    {
      option: "2",
      label: tt.mealsPerDayStep.answerTwo,
      explanation: tt.mealsPerDayStep.answerTwoExplanation,
      subDetail: tt.mealsPerDayStep.answerTwoSubDetail,
    },
  ];
}

import { useField, FormikProps, ErrorMessage } from "formik";
import * as React from "react";
import { ErrorInputIcon } from "../../icons/ErrorInputIcon";

type EmailInputProps = {
  id: string;
  placeholderText: string;
} & FormikProps<{}>;

export const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,13}$/i;

export const EmailInput = ({ placeholderText, id, ...props }: EmailInputProps) => {
  const [field, meta] = useField("email");
  return (
    <div className="flex flex-col mb-4 relative">
      <input
        id={id}
        placeholder=" "
        tabIndex={0}
        className={`
        border
        ${
          meta.touched && meta.error && meta.value
            ? "border-red-100 border-opacity-75 border-2 shadow-md"
            : meta.value && !meta.error
            ? "border-green border-opacity-75 border-2 shadow-md"
            : "border-grey"
        }
        focus:bg-white transition-all focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75 focus:shadow-smd px-3.5 pt-4 pb-3 rounded-lg w-full flex-1 appearance-none placeholder-grey focus:outline-none focus:border-transparent`}
        autoComplete="email"
        {...field}
        {...props}
      />
      <label
        htmlFor={id}
        className="text-grey absolute py-3.5 px-5 text-lg duration-300 origin-0 w-full cursor-text pointer-events-none select-none"
      >
        {placeholderText}
      </label>

      {meta.value ? (
        <div className="flex items-center mt-1">
          {meta.error && meta.touched && <ErrorInputIcon className="mr-1" />}
          <ErrorMessage name="email" component="span" className="text-red-100 text-xs block" />
        </div>
      ) : null}
    </div>
  );
};

import React, { useContext, useEffect } from "react";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { SingleChoiceButton } from "../components/SingleChoiceButtton";
import { OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useActor, useSelector } from "@xstate/react";
import { Ranking } from "../../../types/graphql-global-types";
import { navigateToNextStep } from "../utils";

export const cookingTimeSelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.cookingTimeExtent;

export function CookingTimeStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const cookingTime = useSelector(onboarding.machine!, cookingTimeSelector);
  const tt = useOnboardingTranslations();
  const cookingTimeOptions = getOptions(tt);

  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.COOKING_TIME });
  }, [send]);

  const handleChange = (value: Ranking) => {
    send({ type: "SET_PAYLOAD", payload: "cookingTimeExtent", value });
    if (onDone) {
      onDone?.();
    } else {
      navigateToNextStep(OnboardingStep.COOKING_TIME);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.cookingTimeStep.question} className="max-w-xs" />

      {cookingTimeOptions.map(({ option, label, explanation }) => (
        <SingleChoiceButton
          key={option}
          value={option}
          textLabel={label}
          name="cookingTimeExtent"
          explanation={explanation}
          onChange={(event) => handleChange(event.target.value as Ranking)}
          isActive={cookingTime === option}
        />
      ))}
    </div>
  );
}

export function getOptions(
  tt: OnboardingTranslation
): Array<{ option: Ranking; label: string; explanation?: string }> {
  return [
    {
      option: Ranking.LOW,
      label: tt.cookingTimeStep.answerMinimal,
      explanation: tt.cookingTimeStep.answerMinimalExplanation,
    },
    {
      option: Ranking.MEDIUM,
      label: tt.cookingTimeStep.answerAverage,
      explanation: tt.cookingTimeStep.answerAverageExplanation,
    },
    {
      option: Ranking.HIGH,
      label: tt.cookingTimeStep.answerPlenty,
      explanation: tt.cookingTimeStep.answerPlentyExplanation,
    },
  ];
}

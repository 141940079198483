import { useActor, useSelector } from "@xstate/react";
import React, { useContext, useEffect } from "react";
import { Ranking } from "../../../types/graphql-global-types";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { SingleChoiceButton } from "../components/SingleChoiceButtton";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { navigateToNextStep } from "../utils";

export const proteinLevelSelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.proteinTolerance;

export function ProteinLevelStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const proteinLevel = useSelector(onboarding.machine!, proteinLevelSelector);
  const tt = useOnboardingTranslations();
  const proteinLevelOptions = getOptions(tt);

  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.PROTEIN_LEVEL });
  }, [send]);

  const handleChange = (value: Ranking) => {
    send({ type: "SET_PAYLOAD", payload: "proteinTolerance", value });
    if (onDone) {
      onDone?.();
    } else {
      navigateToNextStep(OnboardingStep.PROTEIN_LEVEL);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.proteinLevelStep.question} />

      {proteinLevelOptions.map(({ option, label, explanation }) => (
        <SingleChoiceButton
          key={option}
          value={option}
          textLabel={label}
          name="proteinTolerance"
          explanation={explanation}
          onChange={(event) => handleChange(event.target.value as Ranking)}
          isActive={proteinLevel === option}
        />
      ))}
    </div>
  );
}

export function getOptions(
  tt: OnboardingTranslation
): Array<{ option: Ranking; label: string; explanation?: string }> {
  return [
    {
      option: Ranking.LOW,
      label: tt.proteinLevelStep.answerUnknown,
      explanation: tt.proteinLevelStep.answerUnknownExplanation,
    },
    {
      option: Ranking.MEDIUM,
      label: tt.proteinLevelStep.answerModerate,
      explanation: tt.proteinLevelStep.answerModerateExplanation,
    },
    {
      option: Ranking.HIGH,
      label: tt.proteinLevelStep.answerHigh,
      explanation: tt.proteinLevelStep.answerHighExplanation,
    },
  ];
}

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useActor, useSelector } from "@xstate/react";
import { Field, Form, Formik } from "formik";
import { Link } from "../../../components/Link/Link";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { LoginPage, TermsPage, TermsSection } from "../../../pages";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { OnboardingMachineState, OnboardingStep } from "../types";
import { checkOnboardingPayload, navigateToNextStep, navigateToStep } from "../utils";
import * as yup from "yup";
import { EmailInput, emailRegex } from "../../../components/FormElement/EmailInput";
import { ErrorBox } from "../../../components/LoginPage/components/ErrorBox";
import { useMutation } from "@apollo/client";
import CreateUser from "../../../graphql/CreateUser.graphql";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import { getLocaleBCP47 } from "../../../utils/getLocaleBCP47";
import { sendEvent } from "../../../utils/gtm";
import PasswordInput from "../../../components/FormElement/PasswordInput";
import { Button } from "../../../components/FormElement/Button";
import useGeoCountryCode from "../../../graphql/useGeoCountryCode/useGeoCountryCode";

const onboardingPayloadSelector = (state: OnboardingMachineState) => state.context.onboardingPayload;

const validationSchema = () =>
  yup.object({
    email: yup
      .string()
      .matches(emailRegex, "Please enter a valid email")
      .email("Please enter a valid email")
      .required("Email is required"),
    password: yup.string().min(6, "The password must be at 6 characters").required("Password is required"),
  });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AccountStep(_: RouteComponentProps) {
  const { locale } = useContext(pageContext);
  const onboarding = useContext(OnboardingStateContext);
  const [, send] = useActor(onboarding.machine!);
  const onboardingPayload = useSelector(onboarding.machine!, onboardingPayloadSelector);

  const [country, setCountry] = useState("");
  const { data: geoCountryCode } = useGeoCountryCode();
  const [loading, setLoading] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState<string | null>(null);
  const [, setInternalError] = useState<boolean | null>(null);

  const [createUser] = useMutation(CreateUser);

  const { loginWithPassword, logout, loggedIn } = useContext(LoginContext);

  const localeBCP47 = useMemo(() => getLocaleBCP47(), []);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.ACCOUNT });
  }, [send]);

  useEffect(() => {
    if (checkOnboardingPayload(onboardingPayload)) {
      navigateToStep(locale, OnboardingStep.GENDER);
    }
  }, [locale, onboardingPayload]);

  // Handle geolocation country code result
  useEffect(() => {
    if (geoCountryCode) {
      setCountry(geoCountryCode);
    }
  }, [geoCountryCode]);

  useEffect(() => {
    if (loggedIn === true) navigateToNextStep(OnboardingStep.ACCOUNT);
  }, [loggedIn]);

  // Handle submit
  const handleRegisterAccount = useCallback(
    async (values: any) => {
      if (loading) {
        return null;
      }

      const communicationPrefs = {
        subscribeMemberEmails: true,
        subscribeNewsletterEmails: true,
      };

      const createAccountEvent = {
        eventName: "signup",
        action: "create_account",
        payload: {
          success: true,
          tax_residence: country,
          locale: locale!,
          is_subscribed_to_member_emails: communicationPrefs.subscribeMemberEmails,
          is_subscribed_to_newsletter: communicationPrefs.subscribeNewsletterEmails,
          provider: "email",
        },
      } as const;

      setLoading(true);
      setEmailExistsError(null);
      setInternalError(null);

      if (window?.localStorage.getItem("paymentLoading")) {
        window.localStorage.removeItem("paymentLoading");
      }

      try {
        await logout();

        const { data } = await createUser({
          variables: {
            firstName: "NamePlaceHolder",
            lastName: "NamePlaceHolder",
            email: values.email,
            password: values.password,
            taxResidence: country,
            communicationPrefs,
            language: localeBCP47,
          },
        });

        const token = await data.createUser.token;

        if (!token) {
          setInternalError(true);
          throw new Error("Invalid token");
        } else if (token) {
          sendEvent(createAccountEvent);

          await loginWithPassword(values.email, values.password, true);

          navigateToNextStep(OnboardingStep.ACCOUNT);
        }
      } catch (e) {
        //@ts-ignore
        const errorMessage = e.message;

        // https://github.com/dietdoctor/dietdoctor-api/blob/e02c39a8a42b578527ad11f9d8c4a2000a96d3b9/resolver/mutation_user.go#L20
        if (errorMessage.includes("email already registered") || errorMessage.includes("user already exists")) {
          setEmailExistsError(values.email);
        }

        // https://github.com/dietdoctor/dietdoctor-api/blob/e02c39a8a42b578527ad11f9d8c4a2000a96d3b9/resolver/mutation_user.go#L69
        if (errorMessage.includes("unable to create user in user service")) {
          setInternalError(true);
        }

        sendEvent({
          ...createAccountEvent,
          payload: {
            ...createAccountEvent.payload,
            success: false,
          },
        });
      } finally {
        setLoading(false);
      }
    },
    [loading, country, locale, logout, createUser, localeBCP47, loginWithPassword]
  );

  return (
    <div className="flex flex-col items-center">
      <h3 className="text-3xl text-center max-w-xs mb-6">
        Create an account to see your personalized results{" "}
      </h3>

      {emailExistsError && (
        <ErrorBox className="mb-6">
          This email is already registered <br />
          <Link className="text-red underline" to={LoginPage}>
            Log into that account
          </Link>{" "}
          or try using a different email.
        </ErrorBox>
      )}

      <Formik
        validationSchema={validationSchema()}
        validateOnMount={true}
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={handleRegisterAccount}
      >
        {({ handleSubmit, isValid }) => (
          <Form
            method="POST"
            className="w-full"
            autoComplete="off"
            onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
          >
            <Field
              type="email"
              name="email"
              component={EmailInput}
              placeholderText={"E-mail (Please enter correctly.)"}
            />
            <Field name="password" component={PasswordInput} placeholderText={"Password"} />

            <div className="flex items-center flex-row mb-8">
              <div className="m-0 body-s text-center mx-2">
                By clicking “See my results” I agree to Diet Doctor’s{" "}
                <Link
                  to={TermsPage}
                  query={{ section: TermsSection.TERMS }}
                  className="text-black font-medium underline whitespace-nowrap"
                >
                  Terms of Use
                </Link>{" "}
                and{" "}
                <Link
                  to={TermsPage}
                  query={{ section: TermsSection.PRIVACY_POLICY }}
                  className="text-black font-medium underline whitespace-nowrap"
                >
                  Privacy Policy
                </Link>
                .
              </div>
            </div>
            <Button
              type="submit"
              dataTestid="continue"
              disabled={!isValid}
              loading={loading}
              label={"See my results"}
              className="w-full disabled:!opacity-50 !bg-green hover:!opacity-90"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

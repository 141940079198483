import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useActor } from "@xstate/react";
import { useStateWithLocalStorage } from "../../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";
import CheckBoxChecked from "../components/CheckBoxChecked";
import CheckBoxEmpty from "../components/CheckBoxEmpty";
import { MultiChoiceButton } from "../components/MultiChoiceButton";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { OnboardingStep, OnboardingStepProps } from "../types";
import { navigateToNextStep } from "../utils";

enum FeelAboutFood {
  CRAVINGS = "CRAVINGS",
  STRESSED = "STRESSED",
  GOOD_RELATIONSHIP = "GOOD_RELATIONSHIP",
  BORED = "BORED",
  STRUGGLE_CHOICES = "STRUGGLE_CHOICES",
  EMOTIONAL = "EMOTIONAL",
}

export function FeelAboutFoodStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const [, send] = useActor(onboarding.machine!);

  const [feelAboutFood, setFeelAboutFood] = useStateWithLocalStorage<string[]>(
    "dd/onboarding/feel-about-food",
    []
  );
  const [feelAboutFoodChoices, setFeelAboutFoodChoices] = useState<FeelAboutFood[]>(
    feelAboutFood as FeelAboutFood[]
  );
  const feelAboutFoodOptions = getOptions();

  const hasSomeFeelAboutFoodChoices = useMemo(() => feelAboutFoodChoices.length > 0, [feelAboutFoodChoices]);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.FEEL_ABOUT_FOOD });
  }, [send]);

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (checked) {
      setFeelAboutFoodChoices((previousValue) => [...previousValue, value as FeelAboutFood]);
    } else {
      setFeelAboutFoodChoices((previousValue) => previousValue.filter((e) => e !== value));
    }
  }, []);

  const handleClick = () => {
    navigateToNextStep(OnboardingStep.FEEL_ABOUT_FOOD);
  };

  useEffect(() => {
    setFeelAboutFood(feelAboutFoodChoices);
  }, [feelAboutFoodChoices]);

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={"How do you feel about food at the moment?"} />

      {feelAboutFoodOptions.map(({ option, label }) => (
        <MultiChoiceButton
          key={option}
          checkboxIcon={feelAboutFoodChoices.includes(option) ? <CheckBoxChecked /> : <CheckBoxEmpty />}
          isChecked={feelAboutFoodChoices.includes(option)}
          value={option}
          textLabel={label}
          name="feelaboutfood"
          onChange={(event) => handleOnChange(event)}
        />
      ))}

      <OnboardingButton
        label={hasSomeFeelAboutFoodChoices ? "Continue" : "None of the above"}
        onClick={handleClick}
      />
    </div>
  );
}

export function getOptions(): Array<{ option: FeelAboutFood; label: string }> {
  return [
    {
      option: FeelAboutFood.CRAVINGS,
      label: "I have a lot of cravings",
    },
    {
      option: FeelAboutFood.STRESSED,
      label: "I eat when I’m stressed",
    },
    {
      option: FeelAboutFood.GOOD_RELATIONSHIP,
      label: "I have a good relationship with food",
    },
    {
      option: FeelAboutFood.BORED,
      label: "I eat when I’m bored",
    },
    {
      option: FeelAboutFood.STRUGGLE_CHOICES,
      label: "I try to make healthy choices but am unsure what’s best",
    },
    {
      option: FeelAboutFood.EMOTIONAL,
      label: "I have an emotional attachment to certain foods",
    },
  ];
}

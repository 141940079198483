import { t } from "@lingui/macro";

export type OnboardingTranslation = ReturnType<typeof useOnboardingTranslations>;

export function useOnboardingTranslations() {
  return {
    onboardingPage: t({
      id: "OnboardingPage.title",
      message: "Onboarding — Diet Doctor",
      comment: "The HTML page title.",
    }),

    buttonNext: t({
      id: "OnboardingPage.next.button",
      message: "Next",
    }),

    buttonNota: t({
      id: "OnboardingPage.nota.button",
      message: "None of the above",
    }),

    buttonDone: t({
      id: "OnboardingPage.done.button",
      message: "Done",
    }),

    weightUnitPounds: t({
      id: "OnboardingPage.weightUnit.pounds",
      message: "pounds",
    }),

    weightUnitKg: t({
      id: "OnboardingPage.weightUnit.kg",
      message: "kilos",
    }),

    measurementUnitImperial: t({
      id: "OnboardingPage.measurementUnit.imperial",
      message: "imperial",
    }),

    measurementUnitMetric: t({
      id: "OnboardingPage.measurementUnit.metric",
      message: "metric",
    }),

    buttonStart: t({
      id: "OnboardingPage.start.button",
      message: "Start",
    }),

    buttonAgree: t({
      id: "OnboardingPage.agree.button",
      message: "I agree",
    }),

    buttonGotIt: t({
      id: "OnboardingPage.gotit.button",
      message: "Got it!",
    }),

    buttonNone: t({
      id: "OnboardingPage.none.button",
      message: "None",
    }),

    buttonContinue: t({
      id: "OnboardingPage.continue.button",
      message: "Continue",
    }),

    onBoardingSuccess: {
      heading: t({
        id: "OnboardingPage.onBoardingSuccess.heading",
        message: "Your personalized DD+ experience is waiting in the app!",
      }),
      content: t({
        id: "OnboardingPage.onBoardingSuccess.content",
        message:
          "We combined your preferences with our unique scientific approach to cook up the best recommendations for you. Currently, this feature is only available in the app.",
      }),
      buttonText: t({
        id: "OnboardingPage.onBoardingSuccess.buttonText",
        message: "Get the app",
      }),
    },

    onBoardingBanner: {
      title: t({
        id: "OnboardingPage.onBoardingBanner.title",
        message: "Want better personalization?",
      }),
      content: t({
        id: "OnboardingPage.onBoardingBanner.content",
        message: "Take the survey to try out the beta version of our new recipe matching system.",
      }),
      ctaButtonText: t({
        id: "OnboardingPage.onBoardingBanner.ctaButtonText",
        message: "Start survey",
      }),
      premiumMembershipText: t({
        id: "OnboardingPage.onBoardingBanner.premiumMembershipText",
        message: "Premium Membership",
      }),
    },

    welcomeStep: {
      heading: t({
        id: "OnboardingPage.welcomeStep.heading",
        message: "Welcome to",
      }),
      ddplus: t({
        id: "OnboardingPage.welcomeStep.ddplus",
        message: "Diet Doctor Plus!",
      }),
      content: t({
        id: "OnboardingPage.welcomeStep.content",
        message: `We'll walk you through your setup to help you get started.`,
      }),
    },

    genderStep: {
      question: t({
        id: "OnboardingPage.genderStep.question",
        message: "What sex best describes you?",
      }),
      answerFemale: t({
        id: "OnboardingPage.genderStep.answer.female",
        message: "Female",
      }),
      answerMale: t({
        id: "OnboardingPage.genderStep.answer.male",
        message: "Male",
      }),
      answerOther: t({
        id: "OnboardingPage.genderStep.answer.other",
        message: "Other",
      }),
      descriptionText: t({
        id: "OnboardingPage.genderStep.descriptionText",
        message:
          "Your sex hormone levels, both currently and what they were during your adolescence, affect your protein and energy needs.",
      }),
    },

    ageStep: {
      heading: t({
        id: "OnboardingPage.ageStep.heading",
        message: "Age",
      }),
      birthYear: t({
        id: "OnboardingPage.ageStep.birthYear",
        message: "Year of birth",
      }),
      yearPlaceholder: t({
        id: "OnboardingPage.ageStep.yearPlaceholder",
        message: "YYYY",
      }),
    },

    heightStep: {
      heading: t({
        id: "OnboardingPage.heightStep.heading",
        message: "Height",
      }),
    },

    measurementSystemStep: {
      heading: t({
        id: "OnboardingPage.measurementSystemStep.heading",
        message: "Measurement system",
      }),
    },

    ageHeightStep: {
      heading: t({
        id: "OnboardingPage.ageHeightStep.heading",
        message: "Age & height",
      }),
      birthYear: t({
        id: "OnboardingPage.ageHeightStep.birthYear",
        message: "Year of birth",
      }),
      height: t({
        id: "OnboardingPage.ageHeightStep.height",
        message: "Height",
      }),
      yearPlaceholder: t({
        id: "OnboardingPage.ageHeightStep.yearPlaceholder",
        message: "YYYY",
      }),
    },

    weightLossGoalStep: {
      question: t({
        id: "OnboardingPage.weightLossGoalStep.question",
        message: `What is your goal weight?`,
      }),
      currentWeight: t({
        id: "OnboardingPage.weightLossGoalStep.currentWeight",
        message: "Current weight",
      }),
      goalWeight: t({
        id: "OnboardingPage.weightLossGoalStep.goalWeight",
        message: "Goal weight",
      }),
      current: t({
        id: "OnboardingPage.weightLossGoalStep.placeholder.current",
        message: "Current",
      }),
      goal: t({
        id: "OnboardingPage.weightLossGoalStep.placeholder.goal",
        message: "Goal",
      }),
    },

    stepOneCompletionStep: {
      cheerText: t({
        id: "OnboardingPage.stepOneCompletionStep.cheerText",
        message: "Awesome!",
      }),
      infoText: t({
        id: "OnboardingPage.stepOneCompletionStep.infoText",
        message: "Keep going so that we can better help you reach your goal.",
      }),
    },

    activityLevelStep: {
      question: t({
        id: "OnboardingPage.activityLevelStep.question",
        message: "How physically active are you?",
      }),
      answerLessActive: t({
        id: "OnboardingPage.activityLevelStep.answer.lessActive",
        message: "Less active",
      }),
      answerModerate: t({
        id: "OnboardingPage.activityLevelStep.answer.moderate",
        message: "Moderately active",
      }),
      answerActive: t({
        id: "OnboardingPage.activityLevelStep.answer.active",
        message: "Very active",
      }),
      answerLessActiveExplanation: t({
        id: "OnboardingPage.activityLevelStep.answer.lessActiveExplanation",
        message: "I exercise up to once per week",
      }),
      answerModerateExplanation: t({
        id: "OnboardingPage.activityLevelStep.answer.moderateExplanation",
        message: "I exercise 1 to 3 times per week",
      }),
      answerActiveExplanation: t({
        id: "OnboardingPage.activityLevelStep.answer.activeExplanation",
        message: "I exercise 4 or more times per week",
      }),
    },

    healthConditionStep: {
      question: t({
        id: "OnboardingPage.healthConditionStep.question",
        message: "Do you have any of these health conditions?",
      }),
      answerDiabetesType1: t({
        id: "OnboardingPage.healthConditionStep.answer.diabetesType1",
        message: "Type 1 diabetes",
      }),
      answerDiabetesType2: t({
        id: "OnboardingPage.healthConditionStep.answer.diabetesType2",
        message: "Type 2 diabetes",
      }),
      answerHighBloodPressure: t({
        id: "OnboardingPage.healthConditionStep.answer.highBloodPressure",
        message: "High blood pressure",
      }),
    },

    goalStep: {
      question: t({
        id: "OnboardingPage.goalStep.question",
        message: "What are your main goals right now?",
      }),
      answerLoseWeight: t({
        id: "OnboardingPage.goalStep.answer.loseWeight",
        message: "Lose weight so I can look and feel better",
      }),
      answerImproveHealth: t({
        id: "OnboardingPage.goalStep.answer.improveHealth",
        message: "Improve my overall health and prevent disease",
      }),
      answerManageCravings: t({
        id: "OnboardingPage.goalStep.answer.manageCravings",
        message: "Manage cravings, hunger, or emotional eating",
      }),
      answerTreatHealthCondition: t({
        id: "OnboardingPage.goalStep.answer.treatHealthCondition",
        message:
          "Treat a specific health condition (such as type 2 diabetes, epilepsy, high blood pressure, PCOS, etc.)",
      }),
      answerMealPlanning: t({
        id: "OnboardingPage.goalStep.answer.mealPlanning",
        message: "Get help with meal planning and cooking inspiration",
      }),
      answerFindSupport: t({
        id: "OnboardingPage.goalStep.answer.findSupport",
        message: "Find support from experts and others with similar experiences",
      }),
      answerLearn: t({
        id: "OnboardingPage.goalStep.answer.learn",
        message: "Learn about health and nutrition from the latest evidence-based science",
      }),
    },

    healthConditionInfoStep: {
      heading: t({
        id: "OnboardingPage.healthConditionInfoStep.heading",
        message: "When to ask your doctor",
      }),

      content: {
        p1: t({
          id: "OnboardingPage.healthConditionInfoStep.content.p1",
          message:
            "If you’re using a low carb or keto diet to treat any medical condition, please consult with your healthcare provider first. This is especially true if you take medications for type 1 or type 2 diabetes, or for hypertension.",
        }),

        p2: t({
          id: "OnboardingPage.healthConditionInfoStep.content.p2",
          message:
            "Reducing carbohydrates while on certain medications may cause a dangerous and potentially life-threatening drop in your blood sugar or blood pressure. Always check with your healthcare provider first.",
        }),

        p3: t({
          id: "OnboardingPage.healthConditionInfoStep.content.p3",
          message:
            "If you need a doctor familiar with low carb diets, please consult our Find-a-doctor map or refer your clinician to our free continuing medical education (CME) course.",
        }),
      },
    },

    stepTwoCompletionStep: {
      cheerText: t({
        id: "OnboardingPage.stepTwoCompletionStep.cheerText",
        message: "You’re doing great!",
      }),
      infoText: t({
        id: "OnboardingPage.stepTwoCompletionStep.infoText",
        message: "Time to talk about food.",
      }),
    },

    cuisineInterestStep: {
      question: t({
        id: "OnboardingPage.cuisineInterestStep.question",
        message: "What cuisines make your mouth water?",
      }),
      answerIndian: t({
        id: "OnboardingPage.cuisineInterestStep.answer.indian",
        message: "Indian",
      }),
      answerAsian: t({
        id: "OnboardingPage.cuisineInterestStep.answer.asian",
        message: "Asian",
      }),
      answerMexican: t({
        id: "OnboardingPage.cuisineInterestStep.answer.mexican",
        message: "Mexican",
      }),
      answerItalian: t({
        id: "OnboardingPage.cuisineInterestStep.answer.italian",
        message: "Italian",
      }),
      answerMiddleEastern: t({
        id: "OnboardingPage.cuisineInterestStep.answer.middleEastern",
        message: "Middle Eastern",
      }),
      answerMediterranean: t({
        id: "OnboardingPage.cuisineInterestStep.answer.mediterranean",
        message: "Mediterranean",
      }),
    },

    foodPreferenceStep: {
      question: t({
        id: "OnboardingPage.foodPreferenceStep.question",
        message: "What kind of dishes do you like?",
      }),
      answerSalads: t({
        id: "OnboardingPage.foodPreferenceStep.answer.salads",
        message: "Salads",
      }),
      answerCasseroles: t({
        id: "OnboardingPage.foodPreferenceStep.answer.casseroles",
        message: "Casseroles",
      }),
      answerSoups: t({
        id: "OnboardingPage.foodPreferenceStep.answer.soups",
        message: "Soups & stews",
      }),
      answerGrill: t({
        id: "OnboardingPage.foodPreferenceStep.answer.grill",
        message: "Grill",
      }),
      answerPizza: t({
        id: "OnboardingPage.foodPreferenceStep.answer.pizza",
        message: "Pizza",
      }),
      answerStirFry: t({
        id: "OnboardingPage.foodPreferenceStep.answer.stirFry",
        message: "Stir-fry",
      }),
      answerOmelet: t({
        id: "OnboardingPage.foodPreferenceStep.answer.omelet",
        message: "Omelette",
      }),
    },

    proteinToAvoidStep: {
      question: t({
        id: "OnboardingPage.proteinToAvoidStep.question",
        message: "Proteins to avoid?",
      }),
      answerBeef: t({
        id: "OnboardingPage.proteinToAvoidStep.answer.beef",
        message: "Beef",
      }),
      answerPork: t({
        id: "OnboardingPage.proteinToAvoidStep.answer.pork",
        message: "Pork",
      }),
      answerPoultry: t({
        id: "OnboardingPage.proteinToAvoidStep.answer.Poultry",
        message: "Poultry",
      }),
      answerLamb: t({
        id: "OnboardingPage.proteinToAvoidStep.answer.lamb",
        message: "Lamb",
      }),
      answerFish: t({
        id: "OnboardingPage.proteinToAvoidStep.answer.fish",
        message: "Fish",
      }),
      answerShellfish: t({
        id: "OnboardingPage.proteinToAvoidStep.answer.shellfish",
        message: "Shellfish",
      }),
      answerVegetarian: t({
        id: "OnboardingPage.proteinToAvoidStep.answer.vegetarian",
        message: "Avoid all (vegetarian)",
      }),
    },

    foodsToAvoidStep: {
      question: t({
        id: "OnboardingPage.foodsToAvoidStep.question",
        message: "Other foods to avoid?",
      }),
      answerDairy: t({
        id: "OnboardingPage.foodsToAvoidStep.answer.dairy",
        message: "Dairy",
      }),
      answerEggs: t({
        id: "OnboardingPage.foodsToAvoidStep.answer.eggs",
        message: "Eggs",
      }),
      answerNuts: t({
        id: "OnboardingPage.foodsToAvoidStep.answer.nuts",
        message: "Nuts",
      }),
      descriptionText: t({
        id: "OnboardingPage.foodsToAvoidStep.descriptionText",
        message: "All of our recipes are gluten-free",
      }),
    },

    foodBudgetStep: {
      question: t({
        id: "OnboardingPage.foodBudgetStep.question",
        message: "What’s your food budget?",
      }),
      answerLess: t({
        id: "OnboardingPage.foodBudgetStep.answer.less",
        message: "Smaller",
      }),
      answerAverage: t({
        id: "OnboardingPage.foodBudgetStep.answer.average",
        message: "Average",
      }),
      answerMore: t({
        id: "OnboardingPage.foodBudgetStep.answer.more",
        message: "Bigger",
      }),
      answerLessExplanation: t({
        id: "OnboardingPage.foodBudgetStep.answer.lessExplanation",
        message: "Show me budget-friendly options",
      }),
      answerAverageExplanation: t({
        id: "OnboardingPage.foodBudgetStep.answer.averageExplanation",
        message: "Call me Goldilocks",
      }),
      answerMoreExplanation: t({
        id: "OnboardingPage.foodBudgetStep.answer.moreExplanation",
        message: "No budget concerns",
      }),
    },

    cookingTimeStep: {
      question: t({
        id: "OnboardingPage.cookingTimeStep.question",
        message: "How much time do you have to cook?",
      }),
      answerMinimal: t({
        id: "OnboardingPage.cookingTimeStep.answer.minimal",
        message: "Minimal",
      }),
      answerAverage: t({
        id: "OnboardingPage.cookingTimeStep.answer.average",
        message: "Average",
      }),
      answerPlenty: t({
        id: "OnboardingPage.cookingTimeStep.answer.plenty",
        message: "Plenty",
      }),
      answerMinimalExplanation: t({
        id: "OnboardingPage.cookingTimeStep.answer.minimalExplanation",
        message: "Up to 15 minutes per meal",
      }),
      answerAverageExplanation: t({
        id: "OnboardingPage.cookingTimeStep.answer.averageExplanation",
        message: "Up to 45 minutes per meal",
      }),
      answerPlentyExplanation: t({
        id: "OnboardingPage.cookingTimeStep.answer.plentyExplanation",
        message: "More than 45 minutes per meal",
      }),
    },

    stepThreeCompletionStep: {
      cheerText: t({
        id: "OnboardingPage.stepThreeCompletionStep.cheerText",
        message: "Keep it up!",
      }),
      infoText: t({
        id: "OnboardingPage.stepThreeCompletionStep.infoText",
        message: "You’re just a few questions away from a delicious, personalized experience.",
      }),
    },

    cookingSkillsStep: {
      question: t({
        id: "OnboardingPage.cookingSkillsStep.question",
        message: "How are your cooking skills?",
      }),
      answerBeginner: t({
        id: "OnboardingPage.cookingSkillsStep.answer.beginner",
        message: "Beginner",
      }),
      answerAverage: t({
        id: "OnboardingPage.cookingSkillsStep.answer.average",
        message: "Average",
      }),
      answerMasterChef: t({
        id: "OnboardingPage.cookingSkillsStep.answer.masterChef",
        message: "Pro",
      }),
      answerBeginnerExplanation: t({
        id: "OnboardingPage.cookingSkillsStep.answer.beginnerExplanation",
        message: `I'm still learning`,
      }),
      answerAverageExplanation: t({
        id: "OnboardingPage.cookingSkillsStep.answer.averageExplanation",
        message: "I know my way around",
      }),
      answerMasterChefExplanation: t({
        id: "OnboardingPage.cookingSkillsStep.answer.masterChefExplanation",
        message: "Prepare to be wowed",
      }),
    },

    carbLevelStep: {
      question: t({
        id: "OnboardingPage.carbLevelStep.question",
        message: "How low carb do you want to go?",
      }),
      answerUnknown: t({
        id: "OnboardingPage.carbLevelStep.answer.unknown",
        message: "I’m not sure",
      }),
      answerKeto: t({
        id: "OnboardingPage.carbLevelStep.answer.keto",
        message: "Keto",
      }),
      answerModerate: t({
        id: "OnboardingPage.carbLevelStep.answer.moderate",
        message: "Moderate",
      }),
      answerLiberal: t({
        id: "OnboardingPage.carbLevelStep.answer.liberal",
        message: "Liberal",
      }),
      answerUnknownExplanation: t({
        id: "OnboardingPage.carbLevelStep.answer.unknownExplanation",
        message: "Let us choose the best one for you",
      }),
      answerKetoExplanation: t({
        id: "OnboardingPage.carbLevelStep.answer.ketoExplanation",
        message: "Less than 20 grams of carbs per day",
      }),
      answerModerateExplanation: t({
        id: "OnboardingPage.carbLevelStep.answer.moderateExplanation",
        message: "20 to 50 grams of carbs per day",
      }),
      answerLiberalExplanation: t({
        id: "OnboardingPage.carbLevelStep.answer.liberalExplanation",
        message: "50 to 100 grams of carbs per day",
      }),
    },

    proteinLevelStep: {
      question: t({
        id: "OnboardingPage.proteinLevelStep.question",
        message: "What’s your protein target?",
      }),
      answerUnknown: t({
        id: "OnboardingPage.proteinLevelStep.answer.unknown",
        message: "I’m not sure",
      }),
      answerModerate: t({
        id: "OnboardingPage.proteinLevelStep.answer.moderate",
        message: "Moderate",
      }),
      answerHigh: t({
        id: "OnboardingPage.proteinLevelStep.answer.high",
        message: "High",
      }),
      answerUnknownExplanation: t({
        id: "OnboardingPage.proteinLevelStep.answer.unknownExplanation",
        message: "Let us choose the best one for you",
      }),
      answerModerateExplanation: t({
        id: "OnboardingPage.proteinLevelStep.answer.moderateExplanation",
        message: "90 to 120 grams per day",
      }),
      answerHighExplanation: t({
        id: "OnboardingPage.proteinLevelStep.answer.highExplanation",
        message: "More than 120 grams per day",
      }),
    },

    dietChallengesStep: {
      question: t({
        id: "OnboardingPage.dietChallengesStep.question",
        message: "If you’ve tried dieting in the past, what was your biggest pain point?",
      }),
      answerFeelingHungry: t({
        id: "OnboardingPage.dietChallengesStep.answer.feelingHungry",
        message: "Feeling hungry too often",
      }),
      answerCravingsForCarbs: t({
        id: "OnboardingPage.dietChallengesStep.answer.cravingsForCarbs",
        message: "Cravings for carbs/sweets",
      }),
      answerFoodNotGood: t({
        id: "OnboardingPage.dietChallengesStep.answer.foodNotGood",
        message: "The food wasn’t very good",
      }),
      answerOptionsTooLimited: t({
        id: "OnboardingPage.dietChallengesStep.answer.optionsTooLimited",
        message: "Options were too limited",
      }),
      answerNotToWeightLose: t({
        id: "OnboardingPage.dietChallengesStep.answer.NotToWeightLose",
        message: `I haven't dieted/I'm not here to lose weight`,
      }),
    },

    mealsPerDayStep: {
      question: t({
        id: "OnboardingPage.mealsPerDayStep.question",
        message: "How many meals per day?",
      }),
      answerThree: t({
        id: "OnboardingPage.mealsPerDayStep.answer.three",
        message: "3",
      }),
      answerTwo: t({
        id: "OnboardingPage.mealsPerDayStep.answer.two",
        message: "2",
      }),
      answerThreeExplanation: t({
        id: "OnboardingPage.mealsPerDayStep.answer.threeExplanation",
        message: "Breakfast, lunch, and dinner",
      }),
      answerTwoExplanation: t({
        id: "OnboardingPage.mealsPerDayStep.answer.twoExplanation",
        message: "Lunch and dinner",
      }),
      answerTwoSubDetail: t({
        id: "OnboardingPage.mealsPerDayStep.answer.twoSubDetail",
        message: "(intermittant fasting)",
      }),
    },

    leftOversStep: {
      question: t({
        id: "OnboardingPage.leftOversStep.question",
        message: "Leftovers for lunch?",
      }),
      answerYes: t({
        id: "OnboardingPage.leftOversStep.answer.yes",
        message: "Yes",
      }),
      answerNo: t({
        id: "OnboardingPage.leftOversStep.answer.no",
        message: "No",
      }),
      answerYesExplanation: t({
        id: "OnboardingPage.leftOversStep.answer.yesExplanation",
        message: "I want to save some time",
      }),
      answerNoExplanation: t({
        id: "OnboardingPage.leftOversStep.answer.noExplanation",
        message: "I really like variation",
      }),
    },

    confirmationStep: {
      heading: t({
        id: "OnboardingPage.confirmationStep.heading",
        message: "One last thing",
      }),

      descriptionText: t({
        id: "OnboardingPage.confirmationStep.descriptionText",
        message:
          "To get my personalized recommendations, I give Diet Doctor permission to process my data and then store it in the United States. I understand that I can change this data agreement in my preferences.",
      }),

      errorMessage: t({
        id: "OnboardingPage.confirmationStep.errorMessage",
        message: "Oops, something went wrong.",
      }),
    },
  };
}

import { useState, useEffect } from "react";
import { isBrowser } from "../../utils/ssr";

export function useGoogleOptimizeVariant({ experimentId }: { experimentId: string }) {
  if (!isBrowser()) {
    return { variationId: 0 };
  }

  const [variationId, setVariationId] = useState<number | undefined>(
    Number((window as any)?.google_optimize?.get(experimentId)) || undefined
  );

  useEffect(() => {
    let tries = 80;
    let intervalId: NodeJS.Timeout;
    setTimeout(() => {
      intervalId = setInterval(async () => {
        if (!tries--) {
          clearInterval(intervalId);
        } else if (isBrowser()) {
          if ((window as any).google_optimize && experimentId) {
            setVariationId(Number((window as any).google_optimize.get(experimentId)));
            return clearInterval(intervalId);
          }
        }
      }, 100);
    }, 380);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { variationId };
}

import React, { useContext, useEffect, useMemo, useState } from "react";
import { MultiChoiceButton } from "../components/MultiChoiceButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import CheckBoxEmpty from "../components/CheckBoxEmpty";
import { OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import CheckBoxExcludeChecked from "../components/CheckBoxExcludeChecked";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTranslation } from "../translations";
import { useOnboardingTranslations } from "../translations";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useActor, useSelector } from "@xstate/react";
import { navigateToNextStep } from "../utils";
import { Diet, FoodType } from "../../../types/graphql-global-types";

export const proteinsToAvoidSelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.avoidedFoods;

export const dietSelector = (state: OnboardingMachineState) => state.context.onboardingPayload.diet;

export function ProteinsToAvoidStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const proteinsToAvoidValue = useSelector(onboarding.machine!, proteinsToAvoidSelector);
  const preSelectedValues = proteinsToAvoidValue?.filter((food) => food !== FoodType.FOOD_TYPE_UNSPECIFIED);
  const diet = useSelector(onboarding.machine!, dietSelector);

  const [proteinsToAvoid, setProteinsToAvoid] = useState<FoodType[]>(preSelectedValues ?? []);
  const [isVegetarian, setVegetarian] = useState<boolean>(diet === Diet.VEGETARIAN ? true : false);

  const tt = useOnboardingTranslations();
  const proteinsToAvoidOptions = getOptions(tt);

  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.PROTEINS_TO_AVOID });
  }, [send]);

  useEffect(() => {
    if (typeof proteinsToAvoidValue !== "undefined") {
      setProteinsToAvoid(proteinsToAvoidValue);
    }
  }, [proteinsToAvoidValue]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    checked
      ? setProteinsToAvoid((previousValue) => [...previousValue, value as FoodType])
      : setProteinsToAvoid((previousValue) => previousValue.filter((e) => e !== value));
  };

  const hasAnySelected = useMemo(
    () => proteinsToAvoid.length > 0 || isVegetarian,
    [proteinsToAvoid, isVegetarian]
  );

  const handleClick = () => {
    const hasAnySelected = proteinsToAvoid.length > 0 || isVegetarian;

    send({
      type: "SET_PAYLOAD",
      payload: "diet",
      value: isVegetarian ? Diet.VEGETARIAN : Diet.DIET_UNSPECIFIED,
    });

    send({
      type: "SET_PAYLOAD",
      payload: "avoidedFoods",
      value: hasAnySelected ? proteinsToAvoid : [FoodType.FOOD_TYPE_UNSPECIFIED],
    });

    if (onDone) {
      onDone();
    } else {
      navigateToNextStep(OnboardingStep.PROTEINS_TO_AVOID);
    }
  };

  const toggleIsVegetarian = () => {
    setVegetarian(!isVegetarian);
  };

  const nextLabel = useMemo(
    () => (onDone ? tt.buttonDone : hasAnySelected ? tt.buttonNext : tt.buttonNota),
    [hasAnySelected, onDone, tt.buttonDone, tt.buttonNext, tt.buttonNota]
  );

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.proteinToAvoidStep.question} />

      {proteinsToAvoidOptions.map(({ option, label }) => (
        <MultiChoiceButton
          key={option}
          checkboxIcon={
            proteinsToAvoid.includes(option) || isVegetarian ? <CheckBoxExcludeChecked /> : <CheckBoxEmpty />
          }
          isChecked={proteinsToAvoid.includes(option)}
          value={option}
          textLabel={label}
          name="proteinsToAvoid"
          onChange={handleOnChange}
        />
      ))}

      <MultiChoiceButton
        checkboxIcon={isVegetarian ? <CheckBoxExcludeChecked /> : <CheckBoxEmpty />}
        isChecked={isVegetarian}
        value={Diet.VEGETARIAN}
        textLabel={tt.proteinToAvoidStep.answerVegetarian}
        name="isVegetarianCheck"
        onChange={toggleIsVegetarian}
      />

      <OnboardingButton label={nextLabel} onClick={handleClick} />
    </div>
  );
}

export function getOptions(tt: OnboardingTranslation): Array<{ option: FoodType; label: string }> {
  return [
    {
      option: FoodType.BEEF,
      label: tt.proteinToAvoidStep.answerBeef,
    },
    {
      option: FoodType.PORK,
      label: tt.proteinToAvoidStep.answerPork,
    },
    {
      option: FoodType.POULTRY,
      label: tt.proteinToAvoidStep.answerPoultry,
    },
    {
      option: FoodType.LAMB,
      label: tt.proteinToAvoidStep.answerLamb,
    },
    {
      option: FoodType.FISH,
      label: tt.proteinToAvoidStep.answerFish,
    },
    {
      option: FoodType.SHELLFISH,
      label: tt.proteinToAvoidStep.answerShellfish,
    },
  ];
}

import { navigate } from "gatsby";
import { localePrefix } from "../../pages";
import { MeasurementSystem, Ranking } from "../../types/graphql-global-types";
import { Locale } from "../../types/Locale";
import { UpdateUser_updateUser } from "../../types/UpdateUser";
import { getLocale } from "../../utils/locale";
import { poundsToKilos } from "../../utils/units";
import { steps } from "./steps";
import { CarbLevel, OnboardingPayload, OnboardingStep } from "./types";

export const navigateToNextStep = (currentStep: OnboardingStep) => {
  const stepIndex = getStepIndex(currentStep);
  const locale = getLocale();
  const pathPrefix = `${localePrefix[locale]}/onboarding`;

  const nextStep = steps[stepIndex + 1].step;

  navigate(`${pathPrefix}/${nextStep}`, { replace: false });
};

export const navigateToPreviousStep = (currentStep: OnboardingStep) => {
  const stepIndex = getStepIndex(currentStep);
  const locale = getLocale();
  const pathPrefix = `${localePrefix[locale]}/onboarding`;

  const prevStep = steps[stepIndex - 1].step;

  navigate(`${pathPrefix}/${prevStep}`, { replace: false });
};

export const navigateToStep = (locale: Locale, step: OnboardingStep) => {
  const pathPrefix = `${localePrefix[locale]}/onboarding`;

  navigate(`${pathPrefix}/${step}`, { replace: false });
};

export const getStepIndex = (currentStep: OnboardingStep) => {
  return steps.findIndex(({ step }) => step === currentStep);
};

export function calcProgress(step: number): number {
  return 100 * (step / (steps.length - 1));
}

export const getBgColor = (step: OnboardingStep) => {
  switch (step) {
    // case OnboardingStep.HEALTH_CONDITION_INFO:
    //   return 'bg-green-500'
    // case OnboardingStep.STEP_ONE_COMPLETION:
    // case OnboardingStep.STEP_TWO_COMPLETION:
    // case OnboardingStep.STEP_THREE_COMPLETION:
    //   return 'bg-white'
    default:
      return "bg-lightest-grey";
  }
};

export const changeUnit = (unit: MeasurementSystem) =>
  unit === MeasurementSystem.METRIC ? MeasurementSystem.IMPERIAL : MeasurementSystem.METRIC;

export const weightInKg =
  (unit: MeasurementSystem) =>
  (weight: number): number =>
    unit === MeasurementSystem.IMPERIAL ? poundsToKilos(weight) : weight;

export const checkOnboardingPayload = (payload: OnboardingPayload) => Object.keys(payload).length <= 0;

function rankingToCarbTolerance(ranking: Ranking): CarbLevel {
  return ranking === Ranking.LOW
    ? CarbLevel.LOW
    : ranking === Ranking.MEDIUM
    ? CarbLevel.MEDIUM
    : ranking === Ranking.HIGH
    ? CarbLevel.HIGH
    : CarbLevel.UNKNOWN_CARB;
}

function carbToleranceToRanking(carbTolerance: CarbLevel): Ranking {
  return carbTolerance === CarbLevel.MEDIUM
    ? Ranking.MEDIUM
    : carbTolerance === CarbLevel.HIGH
    ? Ranking.HIGH
    : Ranking.LOW;
}

export function userPreferencesToOnboardingPayload(preferences: UpdateUser_updateUser) {
  return {
    avoidedFoods: preferences.avoidedFoods ?? [],
    carbTolerance: preferences.carbTolerance ? rankingToCarbTolerance(preferences.carbTolerance) : undefined,
    gender: preferences.gender ?? undefined,
    birthYear: preferences.birthYear ?? undefined,
    desiredDishes: preferences.desiredDishes ?? [],
    desiredCuisines: preferences.desiredCuisines ?? [],
    diet: preferences.diet ?? undefined,
    dietChallenge: preferences.dietChallenge ?? undefined,
    healthConditions: preferences.healthConditions ?? [],
    proteinTolerance: preferences.proteinTolerance ?? undefined,
    physicalActivity: preferences.physicalActivity ?? undefined,
    heightCm: preferences.heightCm ?? undefined,
    foodBudget: preferences.foodBudget ?? undefined,
    cookingSkill: preferences.cookingSkill ?? undefined,
    cookingTimeExtent: preferences.cookingTimeExtent ?? undefined,
    dailyMealsCount: preferences.dailyMealsCount ?? undefined,
    measurementSystem: preferences.measurementSystem ?? undefined,
    leftovers: preferences.leftovers ?? undefined,
  };
}

export function onboardingPayloadToUserPreferences(onboardingPayload: OnboardingPayload) {
  return {
    avoidedFoods: onboardingPayload.avoidedFoods ?? null,
    birthYear: onboardingPayload.birthYear ?? null,
    carbTolerance: onboardingPayload.carbTolerance
      ? carbToleranceToRanking(onboardingPayload.carbTolerance)
      : null,
    cookingSkill: onboardingPayload.cookingSkill ?? null,
    cookingTimeExtent: onboardingPayload.cookingTimeExtent ?? null,
    dailyMealsCount: onboardingPayload.dailyMealsCount ?? null,
    desiredCuisines: onboardingPayload.desiredCuisines ?? null,
    desiredDishes: onboardingPayload.desiredDishes ?? null,
    diet: onboardingPayload.diet ?? null,
    dietChallenge: onboardingPayload.dietChallenge ?? null,
    foodBudget: onboardingPayload.foodBudget ?? null,
    gender: onboardingPayload.gender ?? null,
    healthConditions: onboardingPayload.healthConditions ?? null,
    heightCm: onboardingPayload.heightCm ?? null,
    leftovers: onboardingPayload.leftovers ?? null,
    measurementSystem: onboardingPayload.measurementSystem ?? null,
    physicalActivity: onboardingPayload.physicalActivity ?? null,
    proteinTolerance: onboardingPayload.proteinTolerance ?? null,
  };
}

import { useInterpret } from "@xstate/react";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { InterpreterFrom, StateMachine } from "xstate";
import { LoginContext } from "../../components/LoginProvider/LoginProvider";
import { useUserPreferences } from "../user/useUserPreferences";
import { machine } from "./machine";
import { OnboardingMachineContext, OnboardingMachineEvents } from "./types";
import { userPreferencesToOnboardingPayload } from "./utils";

export const OnboardingStateContext = createContext<{
  machine?: InterpreterFrom<
    StateMachine<
      OnboardingMachineContext,
      any,
      OnboardingMachineEvents,
      {
        value: any;
        context: OnboardingMachineContext;
      }
    >
  >;
}>({});

export const OnboardingStateProvider: React.FunctionComponent<{ children?: React.ReactNode }> = (props) => {
  const { preferences, updateUserPreferences } = useUserPreferences();
  const { firebaseUserId } = useContext(LoginContext);
  const m = useMemo(() => machine({ updateUserPreferences }), [updateUserPreferences]);
  const onboardingMachine = useInterpret(m);
  onboardingMachine.start();

  useEffect(() => {
    if (typeof preferences !== "undefined") {
      onboardingMachine.send({
        type: "SET_INITIAL_PAYLOAD",
        payload: userPreferencesToOnboardingPayload(preferences),
      });
    }
  }, [onboardingMachine, preferences]);

  useEffect(() => {
    if (typeof firebaseUserId !== "undefined") {
      onboardingMachine.send({
        type: "SET_FIREBASE_USER_ID",
        firebaseUserId,
      });
    }
  });

  return (
    <OnboardingStateContext.Provider value={{ machine: onboardingMachine }}>
      {props.children}
    </OnboardingStateContext.Provider>
  );
};

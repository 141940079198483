import { RouteComponentProps } from "@reach/router";
import { useActor } from "@xstate/react";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext, useEffect } from "react";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useOnboardingTranslations } from "../translations";
import { OnboardingStep } from "../types";
import { navigateToNextStep } from "../utils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function StepSixCompletionSplash(_: RouteComponentProps) {
  const tt = useOnboardingTranslations();

  const onboarding = useContext(OnboardingStateContext);
  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.STEP_SIX_COMPLETION });
  }, [send]);

  return (
    <div className="">
      <div className="flex flex-col items-center">
        <OnboardingTitle
          title={"There is no one-size-fits-all diet. That’s why 90% of diets fail."}
          className="mb-3 max-w-sm"
        />

        <StaticImage
          src="../../../../static/images/onboarding-AB-test/diets-weight-graph.png"
          placeholder="none"
          height={320}
          objectFit="contain"
          alt="left"
          className="max-w-lg mt-5"
          loading="eager"
        />

        <p className="m-0 text-lg text-center mt-5 max-w-sm">
          Our unique algorithm recommends recipes tailored to your preferences — and goals.
        </p>
        <p className="m-0 text-lg text-center mt-5 max-w-sm pb-4">
          Discover healthier options and foods you’ll love, while creating a sustainable lifestyle.
        </p>

        <OnboardingButton
          label={tt.buttonContinue}
          onClick={() => navigateToNextStep(OnboardingStep.STEP_SIX_COMPLETION)}
        />
      </div>
    </div>
  );
}

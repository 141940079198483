import React, { useContext, useEffect, useState } from "react";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { SingleChoiceButton } from "../components/SingleChoiceButtton";
import { OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { navigateToNextStep } from "../utils";
import { useActor, useSelector } from "@xstate/react";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { Gender } from "../../../types/graphql-global-types";

export const genderSelector = (state: OnboardingMachineState) => state.context.onboardingPayload.gender;

export function GenderStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const genderValue = useSelector(onboarding.machine!, genderSelector);
  const [gender, setGender] = useState<Gender | undefined>(genderValue);
  const tt = useOnboardingTranslations();
  const genderStepOptions = getOptions(tt);

  const [, send] = useActor(onboarding.machine!);

  const handleChange = (value: Gender) => {
    send({ type: "SET_PAYLOAD", payload: "gender", value });
    if (onDone) {
      onDone?.();
    } else {
      navigateToNextStep(OnboardingStep.GENDER);
    }
  };

  useEffect(() => {
    setGender(genderValue);
  }, [genderValue]);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.GENDER });
  }, [send]);

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.genderStep.question} />

      {genderStepOptions.map(({ option, label }) => (
        <SingleChoiceButton
          key={option}
          value={option}
          textLabel={label}
          name="gender"
          onChange={(event) => handleChange(event.target.value as Gender)}
          isActive={gender === option}
        />
      ))}

      <p className="text-center">{tt.genderStep.descriptionText}</p>
    </div>
  );
}

export function getOptions(tt: OnboardingTranslation) {
  return [
    {
      option: Gender.FEMALE,
      label: tt.genderStep.answerFemale,
    },
    {
      option: Gender.MALE,
      label: tt.genderStep.answerMale,
    },
    {
      option: Gender.OTHER,
      label: tt.genderStep.answerOther,
    },
  ];
}

import { useCallback, useState } from "react";
import { useStateWithLocalStorage } from "../../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";
import { GenerateMealPlan_generateMealplan } from "../../../types/generateMealplan";
import { client } from "../../../utils/apollo-client";
import { MealPlanInputInterface, mealPlanMapper } from "../../../utils/mealplan";
import { mealPlanGenerator } from "../mealplan/mealPlanGenerator";

export function useAnonymousMealPlanGenerator() {
  const [mealPlanPreferences] = useStateWithLocalStorage("mealPlanPreferences", null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const [anonymousMealPlan, setAnonymousMealPlan] = useState<GenerateMealPlan_generateMealplan>();
  const [, setAnonymousMealPlanInput] = useStateWithLocalStorage<string>(
    "dd/onboarding/anonymous-meal-plan-input",
    ""
  );
  const generate = useCallback(async () => {
    setError(false);
    setSaving(true);

    try {
      const mealPlan = await mealPlanGenerator({
        client,
        userId: "",
        hasPremiumMembership: true,
        input: mealPlanPreferences!,
      });

      setAnonymousMealPlan(mealPlan);
      setAnonymousMealPlanInput(JSON.stringify(mealPlanMapper(mealPlan as MealPlanInputInterface)));
    } catch (error) {
      setError(true);
    } finally {
      setSaving(false);
    }
  }, [mealPlanPreferences, setAnonymousMealPlanInput]);

  return { generate, saving, error, anonymousMealPlan };
}

import { useSelector } from "@xstate/react";
import React, { useContext } from "react";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { OnboardingMachineState, OnboardingStep } from "../types";
import { calcProgress, getStepIndex, navigateToPreviousStep } from "../utils";
import { BackButton } from "./BackButton";
import { ProgressBar } from "./ProgressBar";

const stepSelector = (state: OnboardingMachineState) => state.context.step;

export const OnboardingHeader = () => {
  const onboarding = useContext(OnboardingStateContext);
  const step = useSelector(onboarding.machine!, stepSelector);

  const stepIndex = getStepIndex(step);
  const progress = calcProgress(stepIndex);

  const handleBack = () => {
    navigateToPreviousStep(step);
  };

  return (
    <header className="p-5 mx-auto max-w-md w-full">
      <div className="flex flex-row items-center">
        {step === OnboardingStep.GENDER ? (
          <div className="w-14 h-10"></div>
        ) : (
          <BackButton onClick={handleBack} />
        )}
        <ProgressBar progress={progress} />
        <div className="w-14"></div>
      </div>
    </header>
  );
};

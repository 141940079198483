import React from "react";

export default function CheckBoxChecked({ color = "#06B05B" }, props: any) {
  return (
    <svg
      {...props}
      className="shrink-0"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill={color} />
      <path
        d="M6 13.5L10.6154 18L18 6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import * as React from "react";

export const ExclamationIcon = ({ className }: React.SVGProps<SVGSVGElement>) => (
  <svg
    className={`${className} block`}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z" fill="#EB1B1B" />
  </svg>
);

import React, { useCallback, useState } from "react";
import { ErrorMessage, FormikProps, useField } from "formik";
import InvisibleIcon from "../../icons/InvisibleIcon";
import VisibleIcon from "../../icons/VisibleIcon";
import { ErrorInputIcon } from "../../icons/ErrorInputIcon";

type PasswordInputProps = {
  placeholderText: string;
  id: string;
} & FormikProps<{}>;

const PasswordInput = ({ placeholderText, id, ...props }: PasswordInputProps) => {
  const [shouldShowPassword, toggleShowPassword] = useState(false);
  const [field, meta] = useField("password");

  const handleShowPassword = useCallback(() => toggleShowPassword(!shouldShowPassword), [shouldShowPassword]);

  return (
    <div className="mb-4">
      <div className="input-container relative">
        <div className="absolute right-3 opacity-50 hover:opacity-75 top-1/2 -translate-y-1/2 h-6 transition delay-150 cursor-pointer">
          {shouldShowPassword ? (
            <VisibleIcon onClick={handleShowPassword} />
          ) : (
            <InvisibleIcon onClick={handleShowPassword} />
          )}
        </div>
        <input
          type={shouldShowPassword ? "text" : "password"}
          tabIndex={0}
          className={`${
            meta.touched && meta.error && meta.value
              ? "border-red-100 border-opacity-75 border-2 shadow-md"
              : "border-grey"
          } ${
            meta.value && !meta.error && "border-green border-opacity-75 border-2 shadow-md"
          } focus:bg-white transition-all border border-grey focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75 focus:shadow-md py-3.5 pl-3 pr-10 rounded-lg w-full flex-1 appearance-none placeholder-grey focus:outline-none focus:border-transparent`}
          placeholder=" "
          {...field}
          {...props}
        />
        <label
          htmlFor={id}
          className="text-grey absolute top-0 left-0 py-3.5 px-5 text-lg duration-300 origin-0 w-full cursor-text pointer-events-none select-none"
        >
          {placeholderText}
        </label>
      </div>
      {meta.value ? (
        <div className="flex items-center mt-1">
          {meta.error && meta.touched && <ErrorInputIcon className="mr-1" />}
          <ErrorMessage name="password" component="span" className="text-red-100 text-xs block" />
        </div>
      ) : null}
    </div>
  );
};

export default PasswordInput;

import addDays from "date-fns/addDays";
import { addDoc, collection, Firestore, getDocs, query, updateDoc, where } from "firebase/firestore";

export const setCurrentWeight = async (db: Firestore, userId: string, date: Date, weight: number) => {
  const collectionRef = collection(db, "tracker", userId, "weight");
  const q = query(collectionRef, where("date", ">=", date), where("date", "<", addDays(date, 1)));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.docs.length >= 1) {
    return updateDoc(querySnapshot.docs[0].ref, { date, weight });
  } else {
    return addDoc(collectionRef, { date, weight });
  }
};

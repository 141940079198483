import { useSelector } from "@xstate/react";
import React, { useContext } from "react";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { OnboardingMachineState, OnboardingStep } from "../types";
import { getBgColor } from "../utils";
import { OnboardingHeader } from "./OnboardingHeader";

type LayoutProps = {
  children: React.ReactNode;
};

const stepSelector = (state: OnboardingMachineState) => state.context.step;

export const Layout = ({ children }: LayoutProps) => {
  const onboarding = useContext(OnboardingStateContext);

  const step = useSelector(onboarding.machine!, stepSelector);

  const bgColor = getBgColor(step);

  return (
    <div className={`${bgColor} min-h-screen pb-24 sm:pb-5 flex flex-col select-none`}>
      {step !== OnboardingStep.WELCOME && <OnboardingHeader />}
      <div className="mx-auto max-w-md w-full px-5">
        <div className="relative">{children}</div>
      </div>
    </div>
  );
};

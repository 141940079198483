import React, { useContext, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { useActor } from "@xstate/react";
import { StaticImage } from "gatsby-plugin-image";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useOnboardingTranslations } from "../translations";
import { OnboardingStep } from "../types";
import { navigateToNextStep } from "../utils";
import { OnboardingTitle } from "../components/OnboardingTitle";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function StepOneCompletionSplash(_: RouteComponentProps) {
  const tt = useOnboardingTranslations();

  const onboarding = useContext(OnboardingStateContext);
  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.STEP_ONE_COMPLETION });
  }, [send]);

  return (
    <div className="">
      <div className="flex flex-col items-center">
        <OnboardingTitle
          title="Diet Doctor creates sustainable results by helping you eat better, without restrictive
          dieting or counting calories."
          className="mb-5"
        />

        <StaticImage
          src="../../../../static/images/onboarding-AB-test/long-term-results-graph.png"
          placeholder="none"
          height={320}
          objectFit="contain"
          alt="left"
          className="max-w-lg mb-5"
          loading="eager"
        />

        <OnboardingButton
          label={tt.buttonContinue}
          onClick={() => navigateToNextStep(OnboardingStep.STEP_ONE_COMPLETION)}
        />
      </div>
    </div>
  );
}

import { RouteComponentProps } from "@reach/router";
import { useActor } from "@xstate/react";
import React, { useContext, useEffect } from "react";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useOnboardingTranslations } from "../translations";
import { OnboardingStep } from "../types";
import { navigateToNextStep } from "../utils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function StepFourCompletionSplash(_: RouteComponentProps) {
  const tt = useOnboardingTranslations();

  const onboarding = useContext(OnboardingStateContext);
  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.STEP_FOUR_COMPLETION });
  }, [send]);

  return (
    <div className="mt-24">
      <div className="flex flex-col items-center">
        <p className="text-8xl">🎉</p>
        <OnboardingTitle title="Keep it up!" className="mb-3" />
        <p className="m-0 body-l text-center">
          {"You're just a few questions away from a delicious, personalized experience."}
        </p>
        <OnboardingButton
          label={tt.buttonContinue}
          onClick={() => navigateToNextStep(OnboardingStep.STEP_FOUR_COMPLETION)}
        />
      </div>
    </div>
  );
}

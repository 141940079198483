import { RouteComponentProps } from "@reach/router";
import { useActor, useSelector } from "@xstate/react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { useStateWithLocalStorage } from "../../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";
import { MeasurementSystem } from "../../../types/graphql-global-types";
import { Locale } from "../../../types/Locale";
import { InputField } from "../components/InputField";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { UnitSwitcher } from "../components/UnitSwitcher";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useOnboardingTranslations } from "../translations";
import { OnboardingMachineState, OnboardingStep } from "../types";
import { changeUnit, navigateToNextStep, weightInKg } from "../utils";

const measurementUnitSelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.measurementSystem;
const weightSelector = (state: OnboardingMachineState) => state.context.weight;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function WeightLossGoalStep(_: RouteComponentProps) {
  const { locale } = useContext(pageContext);
  const onboarding = useContext(OnboardingStateContext);
  const measurementUnit = useSelector(onboarding.machine!, measurementUnitSelector);
  const weight = useSelector(onboarding.machine!, weightSelector);
  const { currentWeight: cw, goalWeight: gw } = weight;
  const initailUnit = locale === Locale.SV ? MeasurementSystem.METRIC : measurementUnit;
  const [unit, setUnit] = useState<MeasurementSystem>(initailUnit ?? MeasurementSystem.IMPERIAL);
  const isImperial = useMemo(() => unit === MeasurementSystem.IMPERIAL, [unit]);

  const tt = useOnboardingTranslations();
  const [currentWeight, setCurrentWeight] = useState<string>(cw ? cw.toString() : "");
  const [goalWeight, setGoalWeight] = useState<string>(gw ? gw.toString() : "");

  const [, setLocalStorageCurrentWeight] = useStateWithLocalStorage<number>("dd/onboarding/current-weight", -1);
  const [, setLocalStorageWeightGoal] = useStateWithLocalStorage<number>("dd/onboarding/weight-goal", -1);

  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.WEIGHT_LOSS_GOAL });
  }, [send]);

  const handleClick = () => {
    const goalWeightNum = Number(goalWeight);
    const currentWeightNum = Number(currentWeight);

    const goalWeightInKg = weightInKg(unit)(goalWeightNum);
    const currentWeightInKg = weightInKg(unit)(currentWeightNum);

    // at this point there is no user account, so we save this
    // data temporarly in localStorage, one we have created
    // the account we can send it to Firebase
    setLocalStorageCurrentWeight(currentWeightInKg);
    setLocalStorageWeightGoal(goalWeightInKg);

    navigateToNextStep(OnboardingStep.WEIGHT_LOSS_GOAL);

    send({
      type: "SET_WEIGHT",
      weight: {
        currentWeight: currentWeightNum,
        goalWeight: goalWeightNum,
        weightUnit: unit,
      },
    });
  };

  const handleChangeUnit = useCallback(() => {
    const selectedUnit = changeUnit(unit);
    setUnit(selectedUnit);
  }, [unit, setUnit]);

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.weightLossGoalStep.question} />

      <div className="grid grid-flow-col grid-cols-2 gap-3 mt-5">
        <div>
          <label className="block text-lg text-center mb-3">{tt.weightLossGoalStep.currentWeight}</label>
          <InputField
            min={0}
            value={currentWeight}
            inputType="text"
            placeholderText={tt.weightLossGoalStep.current}
            unit={isImperial ? "lbs" : "kg"}
            pattern="^\d*\.?\d*$"
            maxLength={5}
            onChange={(event) =>
              setCurrentWeight((value) => (event.target.validity.valid ? event.target.value : value))
            }
          />
        </div>

        <div>
          <label className="block text-lg text-center mb-3">{tt.weightLossGoalStep.goalWeight}</label>
          <InputField
            min={0}
            inputType="text"
            pattern="^\d*\.?\d*$"
            maxLength={5}
            value={goalWeight}
            placeholderText={tt.weightLossGoalStep.goal}
            unit={isImperial ? "lbs" : "kg"}
            onChange={(event) =>
              setGoalWeight((value) => (event.target.validity.valid ? event.target.value : value))
            }
          />
        </div>
      </div>

      {locale !== Locale.SV && <UnitSwitcher onChange={handleChangeUnit} switchType="mass" unit={unit} />}

      <OnboardingButton label={tt.buttonNext} onClick={handleClick} />
    </div>
  );
}

import { RouteComponentProps } from "@reach/router";
import { useActor, useSelector } from "@xstate/react";
import { StaticImage } from "gatsby-plugin-image";
import React, { useCallback, useContext, useEffect } from "react";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { OnboardingMachineState, OnboardingStep } from "../types";
import { checkOnboardingPayload, navigateToNextStep, navigateToStep } from "../utils";

const onboardingPayloadSelector = (state: OnboardingMachineState) => state.context.onboardingPayload;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SubmitStep(_: RouteComponentProps) {
  const { locale } = useContext(pageContext);
  const onboarding = useContext(OnboardingStateContext);
  const [, send] = useActor(onboarding.machine!);
  const onboardingPayload = useSelector(onboarding.machine!, onboardingPayloadSelector);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.SUBMIT });
  }, [send]);

  useEffect(() => {
    if (checkOnboardingPayload(onboardingPayload)) {
      navigateToStep(locale, OnboardingStep.GENDER);
    }
  }, [locale, onboardingPayload]);

  const handleOnClick = useCallback(() => {
    navigateToNextStep(OnboardingStep.SUBMIT);
  }, []);

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title="Developed by leading nutrition experts" className="mb-0 max-w-xs" />

      <div className="flex flex-row mt-10 space-x-4">
        <div className="flex flex-col items-center w-[104px]">
          <StaticImage
            src="../../../../static/images/experts/andreas.jpg"
            placeholder="none"
            height={160}
            objectFit="contain"
            alt="andreas"
            className="w-24 rounded-full"
            loading="eager"
          />
          <h3 className="text-base text-center mt-1">Andreas Eenfeldt, MD</h3>
        </div>
        <div className="flex flex-col items-center w-[104px]">
          <StaticImage
            src="../../../../static/images/experts/bret.jpg"
            placeholder="none"
            height={160}
            objectFit="contain"
            alt="bret"
            className="w-24 rounded-full"
            loading="eager"
          />
          <h3 className="text-base text-center mt-1">Bret Scher, MD</h3>
        </div>
        <div className="flex flex-col items-center w-[104px]">
          <StaticImage
            src="../../../../static/images/experts/franziska.jpg"
            placeholder="none"
            height={160}
            objectFit="contain"
            alt="franziska"
            className="w-24 rounded-full"
            loading="eager"
          />
          <h3 className="text-base text-center mt-1">Franziska Spritzler, RD</h3>
        </div>
      </div>

      <p className="text-lg text-center mt-10">
        We provide unbiased guidance rooted in evidence-based information, nutritionally-reviewed recipes that
        satisfy, and inspiring tools to help you reach your goals in a sustainable way.
      </p>

      {/* <div className='flex'>
        <Checkbox
          isChecked={isChecked}
          onChange={handleOnChange}
          labelText={tt.SubmitStep.descriptionText}
        />
      </div>
      */}

      <OnboardingButton label={"Generate my meal plan"} onClick={handleOnClick} />
    </div>
  );
}

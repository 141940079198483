import React, { HTMLAttributes } from "react";

export const BackButton: React.FunctionComponent<HTMLAttributes<HTMLOrSVGElement>> = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="w-14 h-10 flex items-center justify-center cursor-pointer hover:opacity-80 duration-[80ms] transition-opacity"
    >
      <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m8.71164 1.41125-5.23519 5.2352-.85356.85355h1.20711 11.67v1h-11.67-1.20558l.85171.85324 5.22708 5.23646-.70321.7032-7.292893-7.2929 7.294143-7.29414z"
          fill="#1a1a1a"
          stroke="#1a1a1a"
        />
      </svg>
    </div>
  );
};

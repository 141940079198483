import { isBrowser } from "./ssr";

export const getLocaleBCP47: () => "sv-SE" | "es-ES" | "en-US" | undefined = () => {
  if (isBrowser()) {
    const path = window.location.pathname;
    switch (true) {
      case path.includes("/se/"):
        return "sv-SE";
      case path.includes("/es/"):
        return "es-ES";
      default:
        return "en-US";
    }
  }
};

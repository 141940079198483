import React, { useContext, useEffect, useMemo, useState } from "react";
import { MultiChoiceButton } from "../components/MultiChoiceButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import CheckBoxEmpty from "../components/CheckBoxEmpty";
import CheckBoxExcludeChecked from "../components/CheckBoxExcludeChecked";
import { OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useActor, useSelector } from "@xstate/react";
import { navigateToNextStep } from "../utils";
import { FoodType } from "../../../types/graphql-global-types";

export const foodsToAvoidSelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.avoidedFoods;

export function FoodsToAvoidStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const foodsToAvoidValue = useSelector(onboarding.machine!, foodsToAvoidSelector);
  const preSelectedValues = useMemo(
    () => foodsToAvoidValue?.filter((food) => food !== FoodType.FOOD_TYPE_UNSPECIFIED),
    [foodsToAvoidValue]
  );

  const [foodsToAvoid, setFoodsToAvoid] = useState<FoodType[]>(preSelectedValues ?? []);
  const tt = useOnboardingTranslations();
  const foodsToAvoidOptions = getOptions(tt);

  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.FOODS_TO_AVOID });
  }, [send]);

  useEffect(() => {
    setFoodsToAvoid(foodsToAvoidValue ?? []);
  }, [foodsToAvoidValue]);

  const hasAnySelected = useMemo(() => foodsToAvoid.length > 0, [foodsToAvoid.length]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const foods = checked
      ? [...foodsToAvoid, value as FoodType]
      : foodsToAvoid.filter((cuisine) => cuisine !== value);

    const hasAnySelected = foods.length > 0;

    send({
      type: "SET_PAYLOAD",
      payload: "avoidedFoods",
      value: hasAnySelected ? foods : [FoodType.FOOD_TYPE_UNSPECIFIED],
    });
  };

  const handleClick = () => {
    if (onDone) {
      onDone?.();
    } else {
      navigateToNextStep(OnboardingStep.FOODS_TO_AVOID);
    }
  };

  const nextLabel = useMemo(
    () => (onDone ? tt.buttonDone : hasAnySelected ? tt.buttonNext : tt.buttonNota),
    [hasAnySelected, onDone, tt.buttonDone, tt.buttonNext, tt.buttonNota]
  );

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.foodsToAvoidStep.question} />

      {foodsToAvoidOptions.map(({ option, label }) => (
        <MultiChoiceButton
          key={option}
          checkboxIcon={foodsToAvoid.includes(option) ? <CheckBoxExcludeChecked /> : <CheckBoxEmpty />}
          isChecked={foodsToAvoid.includes(option)}
          value={option}
          textLabel={label}
          name="foodsToAvoid"
          onChange={(event) => handleOnChange(event)}
        />
      ))}
      <p>{tt.foodsToAvoidStep.descriptionText}</p>
      <OnboardingButton label={nextLabel} onClick={handleClick} />
    </div>
  );
}

export function getOptions(tt: OnboardingTranslation): Array<{ option: FoodType; label: string }> {
  return [
    {
      option: FoodType.DAIRY,
      label: tt.foodsToAvoidStep.answerDairy,
    },
    {
      option: FoodType.EGGS,
      label: tt.foodsToAvoidStep.answerEggs,
    },
    {
      option: FoodType.NUTS,
      label: tt.foodsToAvoidStep.answerNuts,
    },
  ];
}

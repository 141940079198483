import React, { useContext, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { useActor } from "@xstate/react";
import { StaticImage } from "gatsby-plugin-image";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useOnboardingTranslations } from "../translations";
import { OnboardingStep } from "../types";
import { navigateToNextStep } from "../utils";
import { OnboardingTitle } from "../components/OnboardingTitle";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function StepTwoCompletionSplash(_: RouteComponentProps) {
  const tt = useOnboardingTranslations();

  const onboarding = useContext(OnboardingStateContext);
  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.STEP_TWO_COMPLETION });
  }, [send]);

  return (
    <div>
      <div className="flex flex-col items-center">
        <OnboardingTitle title="We know that changing how you eat can be hard" className="mb-5 max-w-xs" />

        <p className="m-0 text-lg text-center mx-auto max-w-xs">
          {"We simplify it for you, like we have for over 2,000,000 people."}
        </p>

        <div>
          <StaticImage
            src="../../../../static/images/onboarding-AB-test/jane-before-after.jpg"
            placeholder="none"
            height={320}
            objectFit="contain"
            alt="left"
            className="max-w-lg mt-5 rounded-t-xl"
            loading="eager"
          />

          <div className="w-full bg-lighter-grey rounded-b-xl py-5">
            <p className="text-[1.438rem] text-center">
              Jane <b>lost 278 lbs</b>
            </p>
            <p className="text-lg font-medium mt-2.5 text-center max-w-[22rem] px-4 mx-auto ">
              “I no longer think of food as a comfort or an escape. I think of it as precious fuel for my body
              and mind.”
            </p>
          </div>
        </div>

        <OnboardingButton
          label={tt.buttonContinue}
          onClick={() => navigateToNextStep(OnboardingStep.STEP_TWO_COMPLETION)}
        />
      </div>
    </div>
  );
}

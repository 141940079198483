import { RouteComponentProps } from "@reach/router";
import { useActor } from "@xstate/react";
import React, { useContext, useEffect } from "react";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useOnboardingTranslations } from "../translations";
import { OnboardingStep } from "../types";
import { navigateToNextStep } from "../utils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function WelcomeStep(_: RouteComponentProps) {
  const tt = useOnboardingTranslations();

  const onboarding = useContext(OnboardingStateContext);
  const [, send] = useActor(onboarding.machine!);

  const handleClick = () => {
    navigateToNextStep(OnboardingStep.WELCOME);
  };

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.WELCOME });
  }, [send]);

  return (
    <div className="flex flex-col items-center mt-20">
      <h2 className="display-xs text-center mt-4 mb-4">
        {tt.welcomeStep.heading} <br /> {tt.welcomeStep.ddplus}
      </h2>
      <p className="m-0 text-center">{tt.welcomeStep.content}</p>
      <OnboardingButton label={tt.buttonStart} onClick={handleClick} className="pt-32" />
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { useUser } from "../../../hooks/useUser/useUser";
import { useNavigate } from "../../../components/Link/Link";
import { useActor, useSelector } from "@xstate/react";
import { OnboardingMachineState, OnboardingStep } from "../types";
import { checkOnboardingPayload, navigateToStep } from "../utils";
import { useStateWithLocalStorage } from "../../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";

import { LoggedInHomePage, PersonalizedPlanProjectionPage } from "../../../pages";

import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { useAnonymousMealPlanGenerator } from "../hooks/useAnonymousMealPlanGenerator";
import { useOnboardingStore } from "../hooks/useOnboardingStore";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { OnboardingGenderPage } from "../pages";
import { LoadingAnimated } from "../../../components/LoadingAnimated/LoadingAnimated";

const onboardingPayloadSelector = (state: OnboardingMachineState) => state.context.onboardingPayload;

export function GeneratingStep(_: RouteComponentProps) {
  // Locale
  const { locale } = useContext(pageContext);

  // User
  const { loggedIn, firebaseUserId, isPremium } = useUser();

  // Router
  const navigate = useNavigate();

  // Onboarding state
  const onboarding = useContext(OnboardingStateContext);
  const [, send] = useActor(onboarding.machine!);

  // Set current step in state machine
  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.GENERATING });
  }, [send]);

  // Onboarding payload
  const onboardingPayload = useSelector(onboarding.machine!, onboardingPayloadSelector);

  // Redirect to gender step if not enough payload
  useEffect(() => {
    if (checkOnboardingPayload(onboardingPayload)) {
      navigateToStep(locale, OnboardingStep.GENDER);
    }
  }, [locale, onboardingPayload]);

  // If user is logged in, we update onboarding data
  const { update: updateOnboardingData, saving: updateLoading } = useOnboardingStore();

  // Anonymous meal plan local storage state
  const [, setAnonymousMealPlan] = useStateWithLocalStorage<string>("dd/onboarding/anonymous-meal-plan", "");

  // Generate meal plan retry count
  const [generateCountRetry, setGenerateCountRetry] = useState(3);

  // Generate anonymous meal plan API hook
  const {
    generate: generateAnonymousMealPlan,
    anonymousMealPlan,
    error: generationError,
    saving: generationLoading,
  } = useAnonymousMealPlanGenerator();

  // Set finished generated meal plan to local storage and redirect user to projection page
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    // Successfully generated anonymous meal plan
    if (!generationLoading && typeof anonymousMealPlan !== "undefined") {
      setAnonymousMealPlan(JSON.stringify(anonymousMealPlan));
      navigate({
        to: PersonalizedPlanProjectionPage,
        query: {
          content: "onboarding",
        },
      });
    }
    // Generation failed, we retry a few times
    else if (!generationLoading && generationError && generateCountRetry >= 0) {
      setGenerateCountRetry((count) => count - 1);
      timeoutId = setTimeout(() => {
        generateAnonymousMealPlan();
      }, 630);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    anonymousMealPlan,
    generationLoading,
    generationError,
    setAnonymousMealPlan,
    generateCountRetry,
    generateAnonymousMealPlan,
    navigate,
  ]);

  const [animatedDots, setAnimatedDots] = useState("...");
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [phase, setPhase] = useState<"ANALYZING" | "GENERATING">("ANALYZING");

  useEffect(() => {
    const dotsArray = [".", "..", "..."];
    let dotsIndex = 0;
    const dotsInterval = setInterval(() => {
      setAnimatedDots(dotsArray[dotsIndex++]);
      if (dotsIndex === 3) {
        dotsIndex = 0;
      }
    }, 500);

    let loadingIndex = 0;
    const loadingInterval = setInterval(() => {
      loadingIndex++;
      if (loadingIndex <= 8) {
        setLoadingProgress((loadingIndex / 8) * 100);
      } else {
        setLoadingProgress(100);
      }
      if (loadingIndex >= 6) {
        setPhase("GENERATING");
      }
      if (loadingIndex >= 7 && !generationLoading && !updateLoading) {
        if (loggedIn && typeof firebaseUserId !== "undefined") {
          updateOnboardingData({ firebaseUserId })
            .then(() => {
              if (isPremium) {
                navigate({ to: LoggedInHomePage });
              } else {
                navigate({
                  to: PersonalizedPlanProjectionPage,
                  query: {
                    content: "onboarding",
                  },
                });
              }
            })
            .finally(() => {
              clearInterval(loadingInterval);
              clearInterval(dotsInterval);
            });
        } else {
          generateAnonymousMealPlan().finally(() => {
            clearInterval(loadingInterval);
            clearInterval(dotsInterval);
          });
        }
      }
    }, 1000);

    return () => {
      clearInterval(dotsInterval);
      clearInterval(loadingInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col items-center">
      {!generationError ? (
        <>
          <OnboardingTitle
            title={`${phase === "ANALYZING" ? "Analyzing responses" : "Generating meal plan"}${animatedDots}`}
          />

          <div className="bg-white border border-grey w-full h-8 rounded-none ml-5 relative m-5">
            <div
              className="bg-green-300 h-full transition duration-500 rounded-none"
              style={{ width: `${loadingProgress}%` }}
            />
            <div className="absolute w-full inset-y-0 text-center text-black mix-blend-difference font-medium mt-1">
              {Math.round(loadingProgress)}%
            </div>
          </div>

          <p className="text-lg mt-5 text-center">
            Just a moment. We’re building your personalized experience.
          </p>
        </>
      ) : (
        <>
          <h2 className="mb-8 text-center">Something went wrong generating your meal plan 😕</h2>
          {generationLoading ? (
            <>
              <p className="text-lg mt-5 text-center">
                Retrying... {generateCountRetry >= 0 && generateCountRetry}
              </p>
              <LoadingAnimated />
            </>
          ) : (
            <>
              <h3>Please retake the survey</h3>
              <OnboardingButton
                onClick={() =>
                  navigate({
                    to: OnboardingGenderPage,
                  })
                }
                label={"Try again"}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}

import { ActivityLevelStep } from "./steps/ActivityLevelStep";
import { AgeHeightStep } from "./steps/AgeHeightStep";
import { CarbLevelStep } from "./steps/CarbLevelStep";
import { SubmitStep } from "./steps/SubmitStep";
import { CookingSkillsStep } from "./steps/CookingSkillStep";
import { CookingTimeStep } from "./steps/CookingTimeStep";
import { CuisineInterestStep } from "./steps/CuisineInterestStep";
import { DietChallengesStep } from "./steps/DietChallengesStep";
import { FeelAboutFoodStep } from "./steps/FeelAboutFoodStep";
import { FoodBudgetStep } from "./steps/FoodBudgetStep";
import { FoodPreferencetStep } from "./steps/FoodPreferenceStep";
import { FoodsToAvoidStep } from "./steps/FoodsToAvoidStep";
import { GenderStep } from "./steps/GenderStep";
import { GoalStep } from "./steps/GoalStep";
import { HealthConditionInfoStep } from "./steps/HealthConditionInfoStep";
import { HealthConditionStep } from "./steps/HealthConditionStep";
import { LeftOversStep } from "./steps/LeftOversStep";
import { MealsPerDayStep } from "./steps/MealsPerDayStep";
import { ProteinLevelStep } from "./steps/ProteinLevelStep";
import { ProteinsToAvoidStep } from "./steps/ProteinsToAvoidStep";
import { RelatableScenariosStep } from "./steps/RelatableScenariosStep";
import { StepOneCompletionSplash } from "./steps/StepOneCompletionSplash";
import { StepTwoCompletionSplash } from "./steps/StepTwoCompletionSplash";
import { StepThreeCompletionSplash } from "./steps/StepThreeCompletionSplash";
import { StepFourCompletionSplash } from "./steps/StepFourCompletionSplash";
import { StepFiveCompletionSplash } from "./steps/StepFiveCompletionSplash";
import { StepSixCompletionSplash } from "./steps/StepSixCompletionSplash";
import { WeightLossGoalStep } from "./steps/WeightLossGoalStep";
import { WelcomeStep } from "./steps/WelcomeStep";
import { OnboardingStep } from "./types";
import { GeneratingStep } from "./steps/GeneratingStep";
import { AccountStep } from "./steps/AccountStep";

export const steps = [
  {
    step: OnboardingStep.WELCOME,
    component: WelcomeStep,
  },
  {
    step: OnboardingStep.GENDER,
    component: GenderStep,
  },
  {
    step: OnboardingStep.GOAL,
    component: GoalStep,
  },
  {
    step: OnboardingStep.HEIGHT_AGE,
    component: AgeHeightStep,
  },
  {
    step: OnboardingStep.WEIGHT_LOSS_GOAL,
    component: WeightLossGoalStep,
  },
  {
    step: OnboardingStep.STEP_ONE_COMPLETION,
    component: StepOneCompletionSplash,
  },
  {
    step: OnboardingStep.ACTIVITY_LEVEL,
    component: ActivityLevelStep,
  },
  {
    step: OnboardingStep.STEP_TWO_COMPLETION,
    component: StepTwoCompletionSplash,
  },
  {
    step: OnboardingStep.HEALTH_CONDITION,
    component: HealthConditionStep,
  },
  {
    step: OnboardingStep.HEALTH_CONDITION_INFO,
    component: HealthConditionInfoStep,
  },
  {
    step: OnboardingStep.STEP_THREE_COMPLETION,
    component: StepThreeCompletionSplash,
  },
  {
    step: OnboardingStep.FEEL_ABOUT_FOOD,
    component: FeelAboutFoodStep,
  },
  {
    step: OnboardingStep.RELATABLE_SCENARIOS,
    component: RelatableScenariosStep,
  },
  {
    step: OnboardingStep.DIET_CHALLENGES,
    component: DietChallengesStep,
  },
  {
    step: OnboardingStep.STEP_FOUR_COMPLETION,
    component: StepFourCompletionSplash,
  },
  {
    step: OnboardingStep.CUISINE_INTEREST,
    component: CuisineInterestStep,
  },
  {
    step: OnboardingStep.FOOD_PREFERENCES,
    component: FoodPreferencetStep,
  },
  {
    step: OnboardingStep.PROTEINS_TO_AVOID,
    component: ProteinsToAvoidStep,
  },
  {
    step: OnboardingStep.FOODS_TO_AVOID,
    component: FoodsToAvoidStep,
  },
  {
    step: OnboardingStep.STEP_FIVE_COMPLETION,
    component: StepFiveCompletionSplash,
  },
  {
    step: OnboardingStep.BUDGET,
    component: FoodBudgetStep,
  },
  {
    step: OnboardingStep.COOKING_TIME,
    component: CookingTimeStep,
  },
  {
    step: OnboardingStep.STEP_SIX_COMPLETION,
    component: StepSixCompletionSplash,
  },
  {
    step: OnboardingStep.COOKING_SKILL,
    component: CookingSkillsStep,
  },
  {
    step: OnboardingStep.CARB_LEVEL,
    component: CarbLevelStep,
  },
  {
    step: OnboardingStep.PROTEIN_LEVEL,
    component: ProteinLevelStep,
  },
  {
    step: OnboardingStep.MEAL_PER_DAY,
    component: MealsPerDayStep,
  },
  {
    step: OnboardingStep.LEFT_OVERS,
    component: LeftOversStep,
  },
  {
    step: OnboardingStep.SUBMIT,
    component: SubmitStep,
  },
  {
    step: OnboardingStep.ACCOUNT,
    component: AccountStep,
  },
  {
    step: OnboardingStep.GENERATING,
    component: GeneratingStep,
  },
];

import React, { useContext, useEffect, useMemo, useState } from "react";
import CheckBoxChecked from "../components/CheckBoxChecked";
import { MultiChoiceButton } from "../components/MultiChoiceButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import CheckBoxEmpty from "../components/CheckBoxEmpty";
import { OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useActor, useSelector } from "@xstate/react";
import { Cuisine } from "../../../types/graphql-global-types";
import { navigateToNextStep } from "../utils";

export const cuisineInterestSelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.desiredCuisines;

export function CuisineInterestStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const cuisineInterestValues = useSelector(onboarding.machine!, cuisineInterestSelector);

  const preSelectedValues = cuisineInterestValues?.filter((cuisine) => cuisine !== Cuisine.CUISINE_UNSPECIFIED);

  const [cuisineInterest, setCuisineInterest] = useState<Cuisine[]>(preSelectedValues ?? []);
  const hasSomeCuisines = useMemo(() => cuisineInterest.length > 0, [cuisineInterest]);

  const tt = useOnboardingTranslations();
  const cuisineInterestOptions = getOptions(tt);

  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    setCuisineInterest(cuisineInterestValues ?? []);
  }, [cuisineInterestValues]);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.CUISINE_INTEREST });
  }, [send]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const interest = checked
      ? [...cuisineInterest, value as Cuisine]
      : cuisineInterest.filter((cuisine) => cuisine !== value);

    const hasSomeCuisines = interest.length > 0;

    send({
      type: "SET_PAYLOAD",
      payload: "desiredCuisines",
      value: hasSomeCuisines ? interest : [Cuisine.CUISINE_UNSPECIFIED],
    });
  };

  const handleClick = () => {
    if (onDone) {
      onDone();
    } else {
      navigateToNextStep(OnboardingStep.CUISINE_INTEREST);
    }
  };

  const nextLabel = useMemo(
    () => (onDone ? tt.buttonDone : hasSomeCuisines ? tt.buttonNext : tt.buttonNota),
    [hasSomeCuisines, onDone, tt.buttonDone, tt.buttonNext, tt.buttonNota]
  );

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.cuisineInterestStep.question} className="max-w-xs" />

      {cuisineInterestOptions.map(({ option, label }) => (
        <MultiChoiceButton
          key={option}
          checkboxIcon={cuisineInterest.includes(option) ? <CheckBoxChecked /> : <CheckBoxEmpty />}
          isChecked={cuisineInterest.includes(option)}
          value={option}
          textLabel={label}
          name="cuisine"
          onChange={(event) => handleOnChange(event)}
        />
      ))}

      <OnboardingButton label={nextLabel} onClick={handleClick} />
    </div>
  );
}

export function getOptions(tt: OnboardingTranslation): Array<{ option: Cuisine; label: string }> {
  return [
    {
      option: Cuisine.INDIAN,
      label: tt.cuisineInterestStep.answerIndian,
    },
    {
      option: Cuisine.ASIAN,
      label: tt.cuisineInterestStep.answerAsian,
    },
    {
      option: Cuisine.MEXICAN,
      label: tt.cuisineInterestStep.answerMexican,
    },
    {
      option: Cuisine.ITALIAN,
      label: tt.cuisineInterestStep.answerItalian,
    },
    {
      option: Cuisine.MIDDLE_EASTERN,
      label: tt.cuisineInterestStep.answerMiddleEastern,
    },
    {
      option: Cuisine.MEDITERRANEAN,
      label: tt.cuisineInterestStep.answerMediterranean,
    },
  ];
}

import React, { useContext, useEffect, useMemo, useState } from "react";
import CheckBoxChecked from "../components/CheckBoxChecked";
import { MultiChoiceButton } from "../components/MultiChoiceButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import CheckBoxEmpty from "../components/CheckBoxEmpty";
import { OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { OnboardingButton } from "../components/OnboardingButton";
import { useActor, useSelector } from "@xstate/react";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { Dish } from "../../../types/graphql-global-types";
import { navigateToNextStep } from "../utils";

export const foodPreferenceSelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.desiredDishes;

export function FoodPreferencetStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const foodPreferenceValue = useSelector(onboarding.machine!, foodPreferenceSelector);
  const preSelectedValues = foodPreferenceValue?.filter((preference) => preference !== Dish.DISH_UNSPECIFIED);

  const [foodPreference, setFoodPreference] = useState<Dish[]>(preSelectedValues ?? []);

  const tt = useOnboardingTranslations();
  const foodPreferenceOptions = getOptions(tt);

  const [, send] = useActor(onboarding.machine!);

  const hasSomePreferences = useMemo(() => foodPreference.length > 0, [foodPreference]);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.FOOD_PREFERENCES });
  }, [send]);

  useEffect(() => {
    setFoodPreference(foodPreferenceValue ?? []);
  }, [foodPreferenceValue]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const interest = checked
      ? [...foodPreference, value as Dish]
      : foodPreference.filter((cuisine) => cuisine !== value);

    const hasSomeCuisines = interest.length > 0;

    send({
      type: "SET_PAYLOAD",
      payload: "desiredDishes",
      value: hasSomeCuisines ? interest : [Dish.DISH_UNSPECIFIED],
    });
  };

  const handleClick = () => {
    if (onDone) {
      onDone?.();
    } else {
      navigateToNextStep(OnboardingStep.FOOD_PREFERENCES);
    }
  };

  const nextLabel = useMemo(
    () => (onDone ? tt.buttonDone : hasSomePreferences ? tt.buttonNext : tt.buttonNota),
    [hasSomePreferences, onDone, tt.buttonDone, tt.buttonNext, tt.buttonNota]
  );

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.foodPreferenceStep.question} />

      {foodPreferenceOptions.map(({ option, label }) => (
        <MultiChoiceButton
          key={option}
          checkboxIcon={foodPreference.includes(option) ? <CheckBoxChecked /> : <CheckBoxEmpty />}
          isChecked={foodPreference.includes(option)}
          value={option}
          textLabel={label}
          name="desiredDishes"
          onChange={(event) => handleOnChange(event)}
        />
      ))}

      <OnboardingButton label={nextLabel} onClick={handleClick} />
    </div>
  );
}

export function getOptions(tt: OnboardingTranslation): Array<{ option: Dish; label: string }> {
  return [
    {
      option: Dish.SALAD,
      label: tt.foodPreferenceStep.answerSalads,
    },
    {
      option: Dish.CASSEROLE,
      label: tt.foodPreferenceStep.answerCasseroles,
    },
    {
      option: Dish.SOUP,
      label: tt.foodPreferenceStep.answerSoups,
    },
    {
      option: Dish.GRILL,
      label: tt.foodPreferenceStep.answerGrill,
    },
    {
      option: Dish.PIZZA,
      label: tt.foodPreferenceStep.answerPizza,
    },
    {
      option: Dish.STIR_FRY,
      label: tt.foodPreferenceStep.answerStirFry,
    },
    {
      option: Dish.OMELET,
      label: tt.foodPreferenceStep.answerOmelet,
    },
  ];
}

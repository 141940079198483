import { t } from "@lingui/macro";

export type loginValidationTranslations = ReturnType<typeof useLoginValidtionTranslations>;

export function useLoginValidtionTranslations() {
  return {
    loginPageHeading: t({
      id: "LoginPage.heading",
      message: "Log in",
    }),

    enterValidEmail: t({
      id: "LoginPage.inputField.enterValidEmail",
      message: "Please enter a valid email",
    }),

    emailIsRequired: t({
      id: "LoginPage.inputField.emailIsRequired",
      message: "Email is required",
    }),

    requiredField: t({
      id: "LoginPage.inputField.requiredField",
      message: "Required",
    }),

    thePasswordMustBeAtLeast6Characters: t({
      id: "LoginPage.inputField.thePasswordMustBeAtLeast6Characters",
      message: "The password must be at least 6 characters",
    }),

    newToDD: t({
      id: "LoginPage.newToDD",
      message: "New to Diet Doctor?",
    }),

    userAlreadyExistsWithEmailAndPassowrdError: t({
      id: "LoginPage.loginError.userAlreadyExistsWithEmailAndPassowrd",
      message: "This account is not connected with any social provider. Please login with email and password.",
    }),

    userAlreadyExistsWithSocialProvider: t({
      id: "LoginPage.loginError.userAlreadyExistsWithSocialProvider",
      message: "This account already exists with a diffrent provider",
    }),

    emailisNotAvailable: t({
      id: "LoginPage.loginError.emailisNotAvailable",
      message:
        "An email address is required to sign up. Your account is not associated with an email or email permission not granted.",
    }),

    loginGenericError: t({
      id: "LoginPage.loginError.genericError",
      message: "Something went wrong while logging in. Please try again later.",
    }),
  };
}

import React from "react";

type MultiChoiceButtonProps = {
  value: string;
  isChecked: boolean;
  name: string;
  textLabel: string;
  checkboxIcon?: JSX.Element;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const MultiChoiceButton = ({
  value,
  name,
  textLabel,
  onChange,
  checkboxIcon,
  isChecked,
}: MultiChoiceButtonProps) => {
  return (
    <div className="w-full mb-3.5">
      <label className="bg-white block text-lg font-medium px-4 py-4 w-full rounded-lg duration-[80ms] transition-shadow shadow-md hover:shadow label-xl cursor-pointer select-none">
        <input
          className="hidden"
          type="checkbox"
          checked={isChecked}
          value={value}
          name={name}
          onChange={onChange}
        />
        <div className="flex items-center">
          {checkboxIcon}
          <span className={`${isChecked ? "text-grey" : ""} ml-3`}>{textLabel}</span>
        </div>
      </label>
    </div>
  );
};

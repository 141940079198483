import React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const firstScale = keyframes`
from {
  transform: scale(0);
}
to {
  transform: scale(1);
}
`;
const secondScale = keyframes`
from {
  transform: translate(0, 0);
}
to {
  transform: translate(19px, 0);
}
`;
const thirdScale = keyframes`
from {
  transform: scale(1);
}
to {
  transform: scale(0);
}
`;

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
`;

const Dot = styled.div`
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #404040;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-of-type(1) {
    left: 6px;
    animation: ${firstScale} 0.6s infinite;
  }

  &:nth-of-type(2) {
    left: 6px;
    animation: ${secondScale} 0.6s infinite;
  }

  &:nth-of-type(3) {
    left: 26px;
    animation: ${secondScale} 0.6s infinite;
  }

  &:nth-of-type(4) {
    left: 45px;
    animation: ${thirdScale} 0.6s infinite;
  }
`;

export const LoadingAnimated: React.FunctionComponent = () => {
  return (
    <Container>
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </Container>
  );
};

import { useSelector } from "@xstate/react";
import React, { useCallback, useContext } from "react";
import { Link } from "../../../components/Link/Link";
import { useUser } from "../../../hooks/useUser/useUser";
import { CloseIcon } from "../../../icons/CloseIcon";
import { HomePage } from "../../../pages";
import { sendEvent } from "../../../utils/gtm";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { OnboardingMachineState } from "../types";
import { calcProgress, getStepIndex, navigateToPreviousStep } from "../utils";
import { BackButton } from "./BackButton";
import { ProgressBar } from "./ProgressBar";

const stepSelector = (state: OnboardingMachineState) => state.context.step;

export const OnboardingHeader = () => {
  const { isPremium } = useUser();
  const onboarding = useContext(OnboardingStateContext);
  const step = useSelector(onboarding.machine!, stepSelector);

  const stepIndex = getStepIndex(step);
  const progress = calcProgress(stepIndex);

  const handleBack = () => {
    navigateToPreviousStep(step);
  };

  const handleOnClick = useCallback(() => {
    sendEvent({
      eventName: "onboarding",
      action: "onboarding_exit_clicked",
      payload: {
        isPremium,
        step,
      },
    });
  }, [isPremium, step]);

  return (
    <header className="p-5 mx-auto max-w-md w-full">
      <div className="flex flex-row items-center">
        <BackButton onClick={handleBack} />
        <ProgressBar progress={progress} />
        <Link to={HomePage} onClick={handleOnClick} className="text-black ml-5 w-14 h-10 flex items-center">
          <CloseIcon />
        </Link>
      </div>
    </header>
  );
};

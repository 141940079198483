import { assign, createMachine, MachineConfig } from "xstate";
import { onboardingPayloadToMealPlanPreferences } from "../onboarding/onboardingPayloadToMealPlanPreferences";
import { Gender } from "../../types/graphql-global-types";
import { useUserPreferences } from "../user/useUserPreferences";
import {
  OnboardingMachineContext,
  OnboardingMachineEvents,
  OnboardingStep,
  SetFirebaseUserIdEvent,
  SetInitialOnboardingPayloadEvent,
  SetOnboardingPayloadEvent,
  SetStepEvent,
  SetWeightEvent,
} from "./types";
import { onboardingPayloadToUserPreferences } from "./utils";

const onboardingMachineConfig: MachineConfig<OnboardingMachineContext, any, OnboardingMachineEvents> = {
  predictableActionArguments: true,
  id: "onboardingABTest",
  initial: "init",
  context: {
    step: OnboardingStep.WELCOME,
    onboardingPayload: {},
    weight: {},
  },
  on: {
    SET_STEP: {
      actions: "setStep",
    },
    SET_INITIAL_PAYLOAD: {
      actions: ["setInitialOnboardingPayload", "setMealplanPreferences"],
    },
    SET_PAYLOAD: {
      actions: ["setOnboardingPayload", "setMealplanPreferences"],
    },
    SET_WEIGHT: {
      actions: "setWeight",
    },
    UPDATE_USER_PREFERENCES: { actions: ["updatePreferences"] },
    SET_FIREBASE_USER_ID: { actions: ["setFirebaseUserId"] },
  },
  states: {
    init: {
      entry: ["resolveQueryStringPayload"],
    },
  },
};
export const machine = ({
  updateUserPreferences,
}: {
  updateUserPreferences: ReturnType<typeof useUserPreferences>["updateUserPreferences"];
}) =>
  createMachine<OnboardingMachineContext, OnboardingMachineEvents>(onboardingMachineConfig, {
    actions: {
      resolveQueryStringPayload: assign({
        onboardingPayload: ({ onboardingPayload }) => {
          const queryParams = new URLSearchParams(typeof window !== "undefined" ? window?.location.search : "");

          const queryGender = (queryParams.get("gender") as Gender) ?? undefined;

          return typeof queryGender !== "undefined"
            ? {
                ...onboardingPayload,
                gender: queryGender,
              }
            : onboardingPayload;
        },
      }),
      setStep: assign({
        step: (_, event) => (event as SetStepEvent).step,
      }),

      setWeight: assign({
        weight: (_, event) => (event as SetWeightEvent).weight,
      }),

      setOnboardingPayload: assign({
        onboardingPayload: ({ onboardingPayload }, event) => {
          const { payload, value } = event as SetOnboardingPayloadEvent;

          const output = {
            ...onboardingPayload,
            [payload]: value,
          };

          // because at this point there is no user account,
          // we save temporarily the onboarding data in localStorage
          // so we can save it later, (once the account is created)
          localStorage.setItem("dd/onboarding/payload", JSON.stringify(output));

          return output;
        },
      }),

      setInitialOnboardingPayload: assign({
        onboardingPayload: (_, event) => (event as SetInitialOnboardingPayloadEvent).payload,
      }),
      setMealplanPreferences: ({ onboardingPayload }) => {
        // because we are replacing the pmp survey with the onboarding
        // (but we want to keep the data to be able to generate the meal plan)
        // we map the onboarding questions to those in the survey
        const mealPlanPreferences = onboardingPayloadToMealPlanPreferences(new Date(), onboardingPayload);
        localStorage.setItem("mealPlanPreferences", JSON.stringify(mealPlanPreferences));
      },
      updatePreferences: ({ onboardingPayload }) =>
        updateUserPreferences(onboardingPayloadToUserPreferences(onboardingPayload)),
      setFirebaseUserId: assign({
        firebaseUserId: (_, event) => (event as SetFirebaseUserIdEvent).firebaseUserId,
      }),
    },
  });

/**
 * @internal
 */
export enum ErrorCode {
  /** Failed to initialize the survey in time. */
  INITIALIZATION_TIMEOUT = "INITIALIZATION_TIMEOUT",

  /**
   * Backend replied with an error or unexpected response while trying to get meal plan
   * preferences.
   */
  GET_MEAL_PLAN_PREFERENCES_UNEXPECTED_ERROR = "GET_MEAL_PLAN_PREFERENCES_UNEXPECTED_ERROR",

  /** Backend replied with an error or unexpected response while trying to generate a meal plan. */
  GENERATE_MEAL_PLAN_UNEXPECTED_ERROR = "GENERATE_MEAL_PLAN_UNEXPECTED_RESPONSE",

  /** Missing preferences while trying to generate a meal plan. */
  GENERATE_MEAL_PLAN_MISSING_PREFERENCES = "GENERATE_MEAL_PLAN_MISSING_PREFERENCES",

  /** Failed to generate the meal plan in time. */
  GENERATE_MEAL_PLAN_TIMEOUT = "GENERATE_MEAL_PLAN_TIMEOUT",

  /** Meal plan could not be generated with the given preferences. */
  GENERATE_MEAL_PLAN_FAILURE = "GENERATE_MEAL_PLAN_FAILURE",
}

/**
 * @internal
 */
export class SurveyError extends Error {
  public readonly code: ErrorCode;
  public readonly details?: string;
  public readonly reason?: Error;

  public constructor(code: ErrorCode, message?: string, details?: string, reason?: Error) {
    super(message);
    this.code = code;
    this.details = details;
    this.reason = reason;
  }
}

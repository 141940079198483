import React from "react";

export default function CheckBoxExcludeChecked() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="4" fill="#E73668" />
      <path d="M17.8003 18L6.20002 6" stroke="white" strokeLinecap="round" />
      <path d="M17.8001 5.99993L6.1999 17.9999" stroke="white" strokeLinecap="round" />
    </svg>
  );
}

import { useActor, useSelector } from "@xstate/react";
import React, { useContext, useEffect } from "react";
import { Ranking } from "../../../types/graphql-global-types";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { SingleChoiceButton } from "../components/SingleChoiceButtton";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { navigateToNextStep } from "../utils";

export const foodBudgetSelector = (state: OnboardingMachineState) => state.context.onboardingPayload.foodBudget;

export function FoodBudgetStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const foodBudget = useSelector(onboarding.machine!, foodBudgetSelector);
  const tt = useOnboardingTranslations();
  const foodBudgetOptions = getOptions(tt);

  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.BUDGET });
  }, [send]);

  const handleChange = (value: Ranking) => {
    send({ type: "SET_PAYLOAD", payload: "foodBudget", value });
    if (onDone) {
      onDone?.();
    } else {
      navigateToNextStep(OnboardingStep.BUDGET);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.foodBudgetStep.question} />

      {foodBudgetOptions.map(({ option, label, explanation }) => (
        <SingleChoiceButton
          key={option}
          value={option}
          textLabel={label}
          name="foodBudget"
          explanation={explanation}
          onChange={(event) => handleChange(event.target.value as Ranking)}
          isActive={foodBudget === option}
        />
      ))}
    </div>
  );
}

export function getOptions(
  tt: OnboardingTranslation
): Array<{ option: Ranking; label: string; explanation?: string }> {
  return [
    {
      option: Ranking.LOW,
      label: tt.foodBudgetStep.answerLess,
      explanation: tt.foodBudgetStep.answerLessExplanation,
    },
    {
      option: Ranking.MEDIUM,
      label: tt.foodBudgetStep.answerAverage,
      explanation: "Medium food budget",
    },
    {
      option: Ranking.HIGH,
      label: tt.foodBudgetStep.answerMore,
      explanation: "Fewer budget concerns",
    },
  ];
}

import flatten from "lodash/flatten";
import {
  GetMealplanBySlug_mealplanBySlug,
  GetMealplanBySlug_mealplanBySlug_schedule,
  GetMealplanBySlug_mealplanBySlug_schedule_breakfast,
  GetMealplanBySlug_mealplanBySlug_schedule_nutrition,
  GetMealplanBySlug_mealplanBySlug_strictness,
} from "../types/GetMealplanBySlug";
import {
  MealplanDayInput,
  MealplanInput,
  MealType,
  MealplanMealInput,
  MealPlanOrigin,
} from "../types/graphql-global-types";
import { DayKey, RecipeDetails, toDayKey } from "../types/MealPlan";
import { onlyUnique } from "./array";

export enum MealPlanType {
  USER = "user",
  STANDARD = "standard",
}

/**
 * Only (user) meal plans authored by the current user can be edited
 */
export const isEditableMealPlan = (mealplan: GetMealplanBySlug_mealplanBySlug, userId: string) => {
  return mealplan.type === MealPlanType.USER && mealplan.userAuthor === userId;
};

/**
 * Only standard (member) meal plans can be copied
 */
export const isCopyableMealPlan = (mealplan: GetMealplanBySlug_mealplanBySlug) => {
  return mealplan.type === MealPlanType.STANDARD;
};

export const getMealFieldName = (meal: MealType) => {
  switch (meal) {
    case MealType.BREAKFAST:
      return "breakfast";
    case MealType.LUNCH:
      return "lunch";
    case MealType.DINNER:
      return "dinner";
  }
};

export const getMealplanDayRecipes = (day: GetMealplanBySlug_mealplanBySlug_schedule | null) => {
  if (day === null) {
    return [];
  }
  return flatten([day.dinner.recipesDetails, day.lunch.recipesDetails, day.breakfast.recipesDetails]).filter(
    Boolean
  ) as RecipeDetails[];
};

export const isMealActive = (mealplan: GetMealplanBySlug_mealplanBySlug, dayKey: DayKey, mealKey: MealType) => {
  const scheduleDay = mealplan.schedule.find((day) => day && day.name === dayKey);
  const mealField = getMealFieldName(mealKey);
  const meal = scheduleDay && scheduleDay[mealField];
  return (meal && meal.active) || false;
};

interface MealMutation {
  dayKey: DayKey;
  mealKey: MealType;
  newRecipeIds?: string[];
  active?: boolean;
}

const mapMealPlanDayToInput =
  (mealplan: GetMealplanBySlug_mealplanBySlug, mutations: MealMutation[]) =>
  (day: GetMealplanBySlug_mealplanBySlug_schedule): MealplanDayInput => {
    const dayKey = toDayKey(day.name);
    // 1) Create day input from `mealplan`
    let input: MealplanDayInput = {
      name: day.name,
      breakfast: {
        active: isMealActive(mealplan, day.name as DayKey, MealType.BREAKFAST),
        recipes: day.breakfast.recipesDetails
          .map((recipe) => (recipe ? recipe.id : undefined))
          .filter(Boolean) as string[],
      },
      lunch: {
        active: isMealActive(mealplan, day.name as DayKey, MealType.LUNCH),
        recipes: day.lunch.recipesDetails
          .map((recipe) => (recipe ? recipe.id : undefined))
          .filter(Boolean) as string[],
      },
      dinner: {
        active: isMealActive(mealplan, day.name as DayKey, MealType.DINNER),
        recipes: day.dinner.recipesDetails
          .map((recipe) => (recipe ? recipe.id : undefined))
          .filter(Boolean) as string[],
      },
    };
    // 2) Add mutations to the day input
    input = buildDayInputWithMutations(dayKey, mutations, input);
    return input;
  };

const buildDayInputWithMutations = (
  dayKey: DayKey,
  mutations: MealMutation[],
  initialInput?: MealplanDayInput
): MealplanDayInput => {
  const dayMutations = mutations.filter((mutationItem) => mutationItem.dayKey === dayKey);
  let input: MealplanDayInput = initialInput || {
    name: dayKey,
    breakfast: emptyMealInput(),
    lunch: emptyMealInput(),
    dinner: emptyMealInput(),
  };
  for (const mutation of dayMutations) {
    const mealField = getMealFieldName(mutation.mealKey);
    const mealInput: MealplanMealInput = Object.prototype.hasOwnProperty.call(input, mealField)
      ? ({ ...input[mealField] } as MealplanMealInput)
      : emptyMealInput();
    if (typeof mutation.active !== "undefined") {
      mealInput.active = mutation.active;
    }
    if (typeof mutation.newRecipeIds !== "undefined") {
      mealInput.recipes = mutation.newRecipeIds;
    }
    input = {
      ...input,
      [mealField]: mealInput,
    };
  }
  return input;
};

export const buildMealPlanInput = (
  mealplan: GetMealplanBySlug_mealplanBySlug,
  mutations: MealMutation[]
): MealplanInput => {
  // 1) Build input for the days contained on `mealplan`
  const days = Object.values(mealplan.schedule).filter(Boolean) as GetMealplanBySlug_mealplanBySlug_schedule[];
  const mapFn = mapMealPlanDayToInput(mealplan, mutations);
  const input = {
    schedule: days.map(mapFn),
  };
  // 2) Build input for the days from `mutations` that are not in `mealplan`
  const mutationDays = mutations.map((mutation) => mutation.dayKey).filter(onlyUnique);
  for (const dayKey of mutationDays) {
    if (!input.schedule.find((day) => day.name === dayKey)) {
      const dayInput = buildDayInputWithMutations(dayKey, mutations);
      input.schedule.push(dayInput);
    }
  }
  return input;
};

/**
 * Creates an optimistic result for a 'copy recipe' mutation over a mealplan.
 * I.e. creates a copy of the meal plan with the meal field updated with the recipes provided on `variables`.
 * IMPORTANT: The new recipe(s) must be already present on the meal plan (e.g. the recipe should have been copied from
 * the same mealplan)
 */
export const buildUpdatedMealPlanForRecipeCopy = (
  mealplan: GetMealplanBySlug_mealplanBySlug,
  mutation: MealMutation
): GetMealplanBySlug_mealplanBySlug => {
  const mealField = getMealFieldName(mutation.mealKey);
  const recipesDetails = (mutation.newRecipeIds || [])
    .map((recipeId) => findMealPlanRecipe(mealplan, recipeId))
    .filter(Boolean);
  const meal = {
    __typename: "Meal",
    active: true,
    recipesDetails,
  };
  const dayIndex = mealplan.schedule.findIndex((day) => day?.name === mutation.dayKey);
  const updatedSchedule = [...mealplan.schedule];
  if (dayIndex >= 0) {
    const day: GetMealplanBySlug_mealplanBySlug_schedule = {
      ...mealplan.schedule[dayIndex]!,
      [mealField]: meal,
    };
    updatedSchedule.splice(dayIndex, 1, day);
  } else {
    updatedSchedule.push({
      ...emptyMealDay(mutation.dayKey),
      [mealField]: meal,
    });
  }
  return {
    ...mealplan,
    schedule: updatedSchedule,
  };
};

const findMealPlanRecipe = (
  mealplan: GetMealplanBySlug_mealplanBySlug,
  recipeId: string
): RecipeDetails | null => {
  const allRecipes = flatten(mealplan.schedule.map(getMealplanDayRecipes));
  return allRecipes.find((recipe) => recipe.id === recipeId) || null;
};

const emptyMealDay = (name: string): GetMealplanBySlug_mealplanBySlug_schedule => ({
  __typename: "Day",
  name,
  breakfast: emptyMeal(),
  lunch: emptyMeal(),
  dinner: emptyMeal(),
  nutrition: emptyNutrition(),
  strictness: emptyStrictness(),
});

const emptyMeal = (): GetMealplanBySlug_mealplanBySlug_schedule_breakfast => ({
  __typename: "Meal",
  active: null,
  recipesDetails: [],
});

const emptyMealInput = (): MealplanMealInput => ({
  active: true,
  recipes: [],
});

export const emptyNutrition = (): GetMealplanBySlug_mealplanBySlug_schedule_nutrition => ({
  __typename: "Nutrition",
  values: {
    __typename: "NutritionValues",
    carbs: 0,
    fat: 0,
    protein: 0,
    calories: 0,
    fiber: null,
    totalCarbs: 0,
  },
  percentages: {
    __typename: "NutritionPercentages",
    carbs: 0,
    fat: 0,
    protein: 0,
  },
});

const emptyStrictness = (): GetMealplanBySlug_mealplanBySlug_strictness => ({
  __typename: "RatingValuePair",
  rating: "liberal",
});

interface Meal {
  id: string;
  images: { vt: string };
  title: string;
}

interface MealPlanDayMapperInterface {
  breakfast: Meal[];
  lunch: Meal[];
  dinner: Meal[];
  name: string;
}

export interface MealPlanInputInterface {
  name: string;
  description: string;
  schedule: MealPlanDayMapperInterface[];
  slug: string;
  startTime: string;
  endTime: string;
}

const mealPlanMealMapper = (id: string): MealplanMealInput => {
  return { active: true, recipes: [id] };
};

const mealPlanDayMapper = (day: MealPlanDayMapperInterface): MealplanDayInput => {
  return {
    name: day.name,
    breakfast: day.breakfast.length > 0 ? mealPlanMealMapper(day.breakfast[0].id) : null,
    lunch: mealPlanMealMapper(day.lunch[0].id),
    dinner: mealPlanMealMapper(day.dinner[0].id),
  };
};

export const mealPlanMapper = (mealPlan: MealPlanInputInterface): MealplanInput => {
  const mappedSchedule = mealPlan.schedule.map((day: any) => mealPlanDayMapper(day));

  const createMealPlanInput: MealplanInput = {
    title: mealPlan.name,
    description: mealPlan.description,
    schedule: mappedSchedule,
    origin: MealPlanOrigin.GENERATED,
    startTime: mealPlan.startTime,
    endTime: mealPlan.endTime,
  };
  return createMealPlanInput;
};

import { useActor, useSelector } from "@xstate/react";
import React, { useContext, useEffect } from "react";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { SingleChoiceButton } from "../components/SingleChoiceButtton";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { CarbLevel, OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { navigateToNextStep } from "../utils";

export const carbLevelSelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.carbTolerance;

export function CarbLevelStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const carbLevel = useSelector(onboarding.machine!, carbLevelSelector);
  const tt = useOnboardingTranslations();
  const carbLevelOptions = getOptions(tt);

  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.CARB_LEVEL });
  }, [send]);

  const handleChange = (value: CarbLevel) => {
    send({
      type: "SET_PAYLOAD",
      payload: "carbTolerance",
      value: value === CarbLevel.UNKNOWN_CARB ? null : value,
    });
    if (onDone) {
      onDone?.();
    } else {
      navigateToNextStep(OnboardingStep.CARB_LEVEL);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.carbLevelStep.question} className="max-w-[280px]" />

      {carbLevelOptions.map(({ option, label, explanation }) => (
        <SingleChoiceButton
          key={option}
          value={option}
          textLabel={label}
          name="carbLevel"
          explanation={explanation}
          onChange={(event) => handleChange(event.target.value as CarbLevel)}
          isActive={carbLevel === option}
        />
      ))}
    </div>
  );
}

export function getOptions(
  tt: OnboardingTranslation
): Array<{ option: CarbLevel; label: string; explanation?: string }> {
  return [
    {
      option: CarbLevel.LOW,
      label: tt.carbLevelStep.answerKeto,
      explanation: tt.carbLevelStep.answerKetoExplanation,
    },
    {
      option: CarbLevel.MEDIUM,
      label: tt.carbLevelStep.answerModerate,
      explanation: tt.carbLevelStep.answerModerateExplanation,
    },
    {
      option: CarbLevel.HIGH,
      label: tt.carbLevelStep.answerLiberal,
      explanation: tt.carbLevelStep.answerLiberalExplanation,
    },
    {
      option: CarbLevel.UNKNOWN_CARB,
      label: tt.carbLevelStep.answerUnknown,
      explanation: tt.carbLevelStep.answerUnknownExplanation,
    },
  ];
}

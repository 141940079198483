import { useActor, useSelector } from "@xstate/react";
import React, { useContext, useEffect } from "react";
import { DietChallenge } from "../../../types/graphql-global-types";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { SingleChoiceButton } from "../components/SingleChoiceButtton";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { navigateToNextStep } from "../utils";

export const dietChallengeSelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.dietChallenge;

export function DietChallengesStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const dietChallenge = useSelector(onboarding.machine!, dietChallengeSelector);
  const tt = useOnboardingTranslations();
  const dietChallengeOptions = getOptions(tt);
  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.DIET_CHALLENGES });
  }, [send]);

  const handleChange = (value: DietChallenge) => {
    send({ type: "SET_PAYLOAD", payload: "dietChallenge", value });
    if (onDone) {
      onDone?.();
    } else {
      navigateToNextStep(OnboardingStep.DIET_CHALLENGES);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.dietChallengesStep.question} />

      {dietChallengeOptions.map(({ option, label }, index) => (
        <SingleChoiceButton
          key={index}
          value={option}
          textLabel={label}
          name="dietChallenge"
          onChange={(event) => handleChange(event.target.value as DietChallenge)}
          isActive={dietChallenge === option}
        />
      ))}
    </div>
  );
}

export function getOptions(tt: OnboardingTranslation): Array<{ option: DietChallenge; label: string }> {
  return [
    {
      option: DietChallenge.HUNGER,
      label: tt.dietChallengesStep.answerFeelingHungry,
    },
    {
      option: DietChallenge.CRAVINGS,
      label: tt.dietChallengesStep.answerCravingsForCarbs,
    },
    {
      option: DietChallenge.BAD_FOOD,
      label: tt.dietChallengesStep.answerFoodNotGood,
    },
    {
      option: DietChallenge.LIMITED_OPTIONS,
      label: tt.dietChallengesStep.answerOptionsTooLimited,
    },
    {
      option: DietChallenge.NO_INTEREST_IN_DIET,
      label: tt.dietChallengesStep.answerNotToWeightLose,
    },
  ];
}

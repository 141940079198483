import { RouteComponentProps } from "@reach/router";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import CheckBoxChecked from "../components/CheckBoxChecked";
import { MultiChoiceButton } from "../components/MultiChoiceButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import CheckBoxEmpty from "../components/CheckBoxEmpty";
import { Goal, OnboardingMachineState, OnboardingStep } from "../types";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useActor, useSelector } from "@xstate/react";
import { navigateToNextStep } from "../utils";
import { useStateWithLocalStorage } from "../../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";

const goalSelector = (state: OnboardingMachineState) => state.context.onboardingPayload.goals;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function GoalStep(_: RouteComponentProps) {
  const onboarding = useContext(OnboardingStateContext);
  const goalValue = useSelector(onboarding.machine!, goalSelector);
  const preSelectedValues = goalValue?.filter((goal) => goal !== Goal.GOAL_UNSPECIFIED);
  const [, setOnboardingGoal] = useStateWithLocalStorage<string[]>("dd/onboarding/goal", []);

  const [goals, setGoals] = useState<Goal[]>(preSelectedValues ?? []);
  const tt = useOnboardingTranslations();
  const goalOptions = getOptions(tt);

  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.GOAL });
  }, [send]);

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (checked) {
      setGoals((previousValue) => [...previousValue, value as Goal]);
    } else {
      setGoals((previousValue) => previousValue.filter((e) => e !== value));
    }
  }, []);

  const hasSomeGoals = useMemo(() => goals.length > 0, [goals]);

  useEffect(() => {
    send({
      type: "SET_PAYLOAD",
      payload: "goals",
      value: hasSomeGoals ? goals : [Goal.GOAL_UNSPECIFIED],
    });

    setOnboardingGoal(goals);
  }, [goals, hasSomeGoals, send, setOnboardingGoal]);

  const handleClick = () => {
    navigateToNextStep(OnboardingStep.GOAL);
  };

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.goalStep.question} className="max-w-xs" />

      {goalOptions.map(({ option, label }) => (
        <MultiChoiceButton
          key={option}
          checkboxIcon={goals.includes(option) ? <CheckBoxChecked /> : <CheckBoxEmpty />}
          isChecked={goals.includes(option)}
          value={option}
          textLabel={label}
          name="goals"
          onChange={(event) => handleOnChange(event)}
        />
      ))}
      <OnboardingButton label={hasSomeGoals ? tt.buttonContinue : tt.buttonNota} onClick={handleClick} />
    </div>
  );
}

function getOptions(tt: OnboardingTranslation): Array<{ option: Goal; label: string }> {
  return [
    { option: Goal.LOSE_WEIGHT, label: tt.goalStep.answerLoseWeight },
    { option: Goal.IMPROVE_HEALTH, label: tt.goalStep.answerImproveHealth },
    { option: Goal.MANAGE_CRAVINGS, label: tt.goalStep.answerManageCravings },
    { option: Goal.TREAT_HEALTH_CONDITION, label: tt.goalStep.answerTreatHealthCondition },
    { option: Goal.MEAL_PLANNING, label: tt.goalStep.answerMealPlanning },
    { option: Goal.FIND_SUPPORT, label: tt.goalStep.answerFindSupport },
    { option: Goal.LEARN, label: tt.goalStep.answerLearn },
  ];
}

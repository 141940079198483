import { useActor, useSelector } from "@xstate/react";
import React, { useContext, useEffect } from "react";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { SingleChoiceButton } from "../components/SingleChoiceButtton";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { navigateToNextStep } from "../utils";

export const leftOversSelector = (state: OnboardingMachineState) => state.context.onboardingPayload.leftovers;

export function LeftOversStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const leftOvers = useSelector(onboarding.machine!, leftOversSelector);
  const tt = useOnboardingTranslations();
  const leftOversOptions = getOptions(tt);
  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.LEFT_OVERS });
  }, [send]);

  const handleChange = (value: boolean) => {
    send({ type: "SET_PAYLOAD", payload: "leftovers", value });
    if (onDone) {
      onDone?.();
    } else {
      navigateToNextStep(OnboardingStep.LEFT_OVERS);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.leftOversStep.question} />

      {leftOversOptions.map(({ option, label, explanation }, idx) => (
        <SingleChoiceButton
          key={idx}
          value={String(option)}
          textLabel={label}
          name="leftovers"
          explanation={explanation}
          onChange={(event) => handleChange(event.target.value === "true")}
          isActive={leftOvers === option}
        />
      ))}
    </div>
  );
}

function getOptions(
  tt: OnboardingTranslation
): Array<{ option: boolean; label: string; explanation?: string }> {
  return [
    {
      option: true,
      label: tt.leftOversStep.answerYes,
      explanation: tt.leftOversStep.answerYesExplanation,
    },
    {
      option: false,
      label: tt.leftOversStep.answerNo,
      explanation: tt.leftOversStep.answerNoExplanation,
    },
  ];
}

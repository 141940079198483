import React from "react";
import { ExclamationIcon } from "../../../icons/ExclamationIcon";
import { SocialError } from "../../SocialLogin/types";
import { loginValidationTranslations, useLoginValidtionTranslations } from "../translations";

type Props = {
  firebaseError?: SocialError;
  children?: React.ReactNode;
  className?: string;
};

export const ErrorBox = ({ firebaseError, children, className }: Props) => {
  const tt = useLoginValidtionTranslations();

  const errorMessage = firebaseError ? getErrorMessage(firebaseError, tt) : "";

  return (
    <div
      className={`${className} w-full p-4 border-red-100 text-red border bg-orange-200 bg-opacity-20 flex flex-row items-center rounded-lg mb-4`}
    >
      <div className="mr-5">
        <ExclamationIcon />
      </div>
      {firebaseError && errorMessage && <div className="flex-grow">{errorMessage}</div>}
      {children && <div className="flex-grow">{children}</div>}
    </div>
  );
};

const getErrorMessage = (firebaseError: SocialError, tt: loginValidationTranslations) => {
  switch (firebaseError.errorCode) {
    case "auth/web-storage-unsupported":
      return "3rd party cookies appear to be disabled in browser. Enable 3rd party cookies or login with email/password to continue.";

    case "auth/account-exists-with-different-credential":
      return tt.userAlreadyExistsWithSocialProvider;

    case "auth/email-not-exists":
      return tt.emailisNotAvailable;

    default:
      return "Oops! Something went wrong";
  }
};

import React from "react";

type ProgressBarProps = {
  progress: number;
};

export const ProgressBar = ({ progress }: ProgressBarProps) => {
  return (
    <div className="bg-white w-full h-1.5 rounded-xl ml-5">
      <div
        className="bg-green h-full transition duration-150 rounded-xl"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

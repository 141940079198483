import React from "react";
import { MeasurementSystem } from "../../../types/graphql-global-types";
import { useOnboardingTranslations } from "../translations";

type SwitchType = "length" | "mass";

type SwitcherProps = {
  onChange: () => void;
  unit: string;
  switchType: SwitchType;
};

export const UnitSwitcher = ({ onChange, unit, switchType }: SwitcherProps) => {
  const tt = useOnboardingTranslations();
  const selectedToggle = "bg-dark-grey rounded-3xl text-white";
  const unselectedToggle = "";

  const switchLabel1 = switchType === "length" ? tt.measurementUnitImperial : tt.weightUnitPounds;
  const switchLabel2 = switchType === "length" ? tt.measurementUnitMetric : tt.weightUnitKg;

  return (
    <div className="flex w-full justify-center mt-8">
      <div className="flex flex-row">
        <div
          className="cursor-pointer flex justify-center bg-white rounded-full shadow-md p-1"
          onClick={onChange}
        >
          <div
            className={`${
              unit === MeasurementSystem.IMPERIAL ? selectedToggle : unselectedToggle
            } px-2.5 py-1.5`}
          >
            {switchLabel1}
          </div>
          <div
            className={`${unit === MeasurementSystem.METRIC ? selectedToggle : unselectedToggle} px-2.5 py-1.5`}
          >
            {switchLabel2}
          </div>
        </div>
      </div>
    </div>
  );
};

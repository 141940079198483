import React, { useContext, useEffect } from "react";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { SingleChoiceButton } from "../components/SingleChoiceButtton";
import { OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useActor, useSelector } from "@xstate/react";
import { navigateToNextStep } from "../utils";
import { Ranking } from "../../../types/graphql-global-types";

export const activityLevelSelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.physicalActivity;

export function ActivityLevelStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const activityLevel = useSelector(onboarding.machine!, activityLevelSelector);

  const tt = useOnboardingTranslations();
  const activityLevelOptions = getOptions(tt);

  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.ACTIVITY_LEVEL });
  }, [send]);

  const handleChange = (value: Ranking) => {
    send({ type: "SET_PAYLOAD", payload: "physicalActivity", value });
    if (onDone) {
      onDone();
    } else {
      navigateToNextStep(OnboardingStep.ACTIVITY_LEVEL);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.activityLevelStep.question} />

      {activityLevelOptions.map(({ option, label, explanation }) => (
        <SingleChoiceButton
          key={option}
          value={option}
          textLabel={label}
          name="physicalActivity"
          explanation={explanation}
          onChange={(event) => handleChange(event.target.value as Ranking)}
          isActive={activityLevel === option}
        />
      ))}
    </div>
  );
}

export function getOptions(tt: OnboardingTranslation) {
  return [
    {
      option: Ranking.LOW,
      label: tt.activityLevelStep.answerLessActive,
      explanation: tt.activityLevelStep.answerLessActiveExplanation,
    },
    {
      option: Ranking.MEDIUM,
      label: tt.activityLevelStep.answerModerate,
      explanation: tt.activityLevelStep.answerModerateExplanation,
    },
    {
      option: Ranking.HIGH,
      label: tt.activityLevelStep.answerActive,
      explanation: tt.activityLevelStep.answerActiveExplanation,
    },
  ];
}

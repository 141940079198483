import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useActor } from "@xstate/react";
import { useStateWithLocalStorage } from "../../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";
import CheckBoxChecked from "../components/CheckBoxChecked";
import CheckBoxEmpty from "../components/CheckBoxEmpty";
import { MultiChoiceButton } from "../components/MultiChoiceButton";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { OnboardingStep, OnboardingStepProps } from "../types";
import { navigateToNextStep } from "../utils";

enum Scenarios {
  LACK_SUPPORT = "LACK_SUPPORT",
  REGAIN_WEIGHT = "REGAIN_WEIGHT",
  FOOD_CHOICES = "FOOD_CHOICES",
  CONFUSED = "CONFUSED",
  VARIETY = "VARIETY",
  EVENTS = "EVENTS",
  PLANNING = "PLANNING",
}

export function RelatableScenariosStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const [, send] = useActor(onboarding.machine!);

  const [relatableScenarios, setRelatableScenarios] = useStateWithLocalStorage<string[]>(
    "dd/onboarding/relatable-scenarios",
    []
  );
  const [relatableScenariosChoices, setRelatableScenariosChoices] = useState<Scenarios[]>(
    relatableScenarios as Scenarios[]
  );
  const relatableScenariosOptions = getOptions();

  const hasSomeRelatableScenariosChoices = useMemo(
    () => relatableScenariosChoices.length > 0,
    [relatableScenariosChoices]
  );

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.RELATABLE_SCENARIOS });
  }, [send]);

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (checked) {
      setRelatableScenariosChoices((previousValue) => [...previousValue, value as Scenarios]);
    } else {
      setRelatableScenariosChoices((previousValue) => previousValue.filter((e) => e !== value));
    }
  }, []);

  const handleClick = () => {
    navigateToNextStep(OnboardingStep.RELATABLE_SCENARIOS);
  };

  useEffect(() => {
    setRelatableScenarios(relatableScenariosChoices);
  }, [relatableScenariosChoices]);

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={"Which of these scenarios can you relate to?"} />

      {relatableScenariosOptions.map(({ option, label }) => (
        <MultiChoiceButton
          key={option}
          checkboxIcon={relatableScenariosChoices.includes(option) ? <CheckBoxChecked /> : <CheckBoxEmpty />}
          isChecked={relatableScenariosChoices.includes(option)}
          value={option}
          textLabel={label}
          name="scenarios"
          onChange={(event) => handleOnChange(event)}
        />
      ))}

      <OnboardingButton
        label={hasSomeRelatableScenariosChoices ? "Continue" : "None of the above"}
        onClick={handleClick}
      />
    </div>
  );
}

export function getOptions(): Array<{ option: Scenarios; label: string }> {
  return [
    {
      option: Scenarios.LACK_SUPPORT,
      label: "I lack support from family",
    },
    {
      option: Scenarios.REGAIN_WEIGHT,
      label: "I always gain the weight back",
    },
    {
      option: Scenarios.FOOD_CHOICES,
      label: "I don’t know what foods are best for me",
    },
    {
      option: Scenarios.CONFUSED,
      label: "I get confused by conflicting nutrition advice",
    },
    {
      option: Scenarios.VARIETY,
      label: "I get bored with a lack of variety",
    },
    {
      option: Scenarios.EVENTS,
      label: "Special events and holidays are my downfall",
    },
    {
      option: Scenarios.PLANNING,
      label: "I make poor choices when I haven’t planned well",
    },
  ];
}

import React from "react";
import styled from "@emotion/styled";
import { default as colors } from "../../../utils/colors.json";

function CheckBoxEmpty(props: any) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="22" height="22" rx="3.25" stroke={colors.GREY_DARK} strokeWidth="1.75" />
    </svg>
  );
}

const StyledCheckBoxEmpty = styled(CheckBoxEmpty)`
  flex-shrink: 0;
  opacity: 0.5;
  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 1;
  }

  transition: opacity 0.18s;
`;
export default StyledCheckBoxEmpty;

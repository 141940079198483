import React, { useContext, useEffect, useMemo, useState } from "react";
import CheckBoxChecked from "../components/CheckBoxChecked";
import { MultiChoiceButton } from "../components/MultiChoiceButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import CheckBoxEmpty from "../components/CheckBoxEmpty";
import { OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useActor, useSelector } from "@xstate/react";
import { HealthCondition } from "../../../types/graphql-global-types";
import { navigateToNextStep } from "../utils";

export const healthConditionsSelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.healthConditions;

export function HealthConditionStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const healthConditionValues = useSelector(onboarding.machine!, healthConditionsSelector);
  const preSelectedValues = useMemo(
    () => healthConditionValues?.filter((hc) => hc !== HealthCondition.HEALTH_CONDITION_UNSPECIFIED),
    [healthConditionValues]
  );

  const [healthConditions, setHealthConditions] = useState<HealthCondition[]>(preSelectedValues ?? []);
  const tt = useOnboardingTranslations();
  const healthConditionOptions = getOptions(tt);

  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.HEALTH_CONDITION });
  }, [send]);

  useEffect(() => {
    setHealthConditions(healthConditionValues ?? []);
  }, [healthConditionValues]);

  const hasSomeHeathConditions = useMemo(() => healthConditions.length > 0, [healthConditions]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const conditions = checked
      ? [...healthConditions, value as HealthCondition]
      : healthConditions.filter((c) => c !== value);

    const hasSomeHeathConditions = conditions.length > 0;

    send({
      type: "SET_PAYLOAD",
      payload: "healthConditions",
      value: hasSomeHeathConditions ? conditions : [HealthCondition.HEALTH_CONDITION_UNSPECIFIED],
    });
  };

  const handleClick = () => {
    if (onDone) {
      onDone?.();
    } else {
      if (hasSomeHeathConditions) {
        navigateToNextStep(OnboardingStep.HEALTH_CONDITION);
      } else {
        navigateToNextStep(OnboardingStep.HEALTH_CONDITION_INFO);
      }
    }
  };

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.healthConditionStep.question} className="max-w-xs" />

      {healthConditionOptions.map(({ option, label }) => (
        <MultiChoiceButton
          key={option}
          checkboxIcon={healthConditions.includes(option) ? <CheckBoxChecked /> : <CheckBoxEmpty />}
          isChecked={healthConditions.includes(option)}
          value={option}
          textLabel={label}
          name="healthConditions"
          onChange={(event) => handleOnChange(event)}
        />
      ))}
      <OnboardingButton
        label={hasSomeHeathConditions ? tt.buttonContinue : tt.buttonNota}
        onClick={handleClick}
      />
    </div>
  );
}

export default HealthConditionStep;

export function getOptions(tt: OnboardingTranslation): Array<{ option: HealthCondition; label: string }> {
  return [
    {
      option: HealthCondition.TYPE1_DIABETES,
      label: tt.healthConditionStep.answerDiabetesType1,
    },
    {
      option: HealthCondition.TYPE2_DIABETES,
      label: tt.healthConditionStep.answerDiabetesType2,
    },
    {
      option: HealthCondition.HIGH_BLOOD_PRESSURE,
      label: tt.healthConditionStep.answerHighBloodPressure,
    },
  ];
}

import React from "react";

type SingleChoiceButtonProps = {
  value: string;
  name: string;
  textLabel: string;
  isActive: boolean;
  explanation?: string;
  subDetail?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const SingleChoiceButton = ({
  value,
  name,
  textLabel,
  isActive,
  explanation,
  subDetail,
  onChange,
}: SingleChoiceButtonProps) => {
  return (
    <div className="w-full mb-3.5">
      <label
        className={`${
          isActive ? "bg-green text-white" : "bg-white"
        } block text-lg font-medium px-4 py-4 w-full rounded-lg text-center cursor-pointer duration-[80ms] transition-colors active:bg-green active:text-white transition-shadow shadow-md hover:shadow single-choice-button`}
      >
        <input className="hidden" type="radio" value={value} name={name} onChange={onChange} />
        <span className="block text-lg">{textLabel}</span>
        {explanation && (
          <span className={`${isActive ? "text-white" : "text-grey"} block label-l`}>{explanation}</span>
        )}

        {subDetail && (
          <span className={`${isActive ? "text-white" : "text-grey"} block label-l`}>{subDetail}</span>
        )}
      </label>
    </div>
  );
};

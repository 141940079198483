import * as React from "react";

export const ErrorInputIcon = ({ className }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="11"
    height="11"
    className={`${className || ""} inline-block`}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.4945 0.0834961C2.5045 0.0834961 0.083252 2.51016 0.083252 5.50016C0.083252 8.49016 2.5045 10.9168 5.4945 10.9168C8.48992 10.9168 10.9166 8.49016 10.9166 5.50016C10.9166 2.51016 8.48992 0.0834961 5.4945 0.0834961ZM5.49992 9.8335C3.10575 9.8335 1.16659 7.89433 1.16659 5.50016C1.16659 3.106 3.10575 1.16683 5.49992 1.16683C7.89409 1.16683 9.83325 3.106 9.83325 5.50016C9.83325 7.89433 7.89409 9.8335 5.49992 9.8335ZM4.95825 7.12516H6.04159V8.2085H4.95825V7.12516ZM4.95825 2.79183H6.04159V6.04183H4.95825V2.79183Z"
      fill="#E41515"
    />
  </svg>
);

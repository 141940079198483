import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { sha256 } from "js-sha256";
import { GeneratedMealPlan } from "../../../types/GeneratedMealPlan";
import { GenerateMealPlan, GenerateMealPlanVariables } from "../../../types/generateMealplan";
import { MealplanPreferencesInput } from "../../../types/graphql-global-types";
import { sendEvent } from "../../../utils/gtm";
import GenerateMealPlanMutation from "./GenerateMealPlanMutation.graphql";
import { ErrorCode, SurveyError } from "./types";

export async function mealPlanGenerator({
  client,
  userId,
  hasPremiumMembership,
  input,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  userId: string;
  hasPremiumMembership: boolean;
  input: MealplanPreferencesInput;
}): Promise<GeneratedMealPlan> {
  let data: GenerateMealPlan | null | undefined;
  try {
    const response = await client.mutate<GenerateMealPlan, GenerateMealPlanVariables>({
      mutation: GenerateMealPlanMutation,
      variables: { input },
    });
    data = response.data;
    sendEvent({
      eventName: "meal_planner",
      action: "successful_generate_meal_plan",
      payload: {
        preferences: input,
        userId: userId ? sha256(userId) : "",
        isPremium: hasPremiumMembership,
      },
    });
  } catch (error: any) {
    sendEvent({
      eventName: "meal_planner",
      action: "failed_generate_meal_plan",
      payload: {
        preferences: input,
        userId: userId ? sha256(userId) : "",
        isPremium: hasPremiumMembership,
      },
    });
    throw convertError(error);
  }

  if (!data || !data.generateMealplan) {
    throw new SurveyError(
      ErrorCode.GENERATE_MEAL_PLAN_UNEXPECTED_ERROR,
      "Unexpected empty response data",
      JSON.stringify(data)
    );
  }

  return data.generateMealplan;
}

function convertError(error: Error): SurveyError {
  if (
    error.message === "unable to generate mealplan" ||
    error.message === "something went wrong, failed to generate a mealplan"
  ) {
    return new SurveyError(
      ErrorCode.GENERATE_MEAL_PLAN_FAILURE,
      "Unable to generate a meal plan for the given preferences"
    );
  }

  return new SurveyError(
    ErrorCode.GENERATE_MEAL_PLAN_UNEXPECTED_ERROR,
    "Failed to generate meal plan",
    undefined,
    error
  );
}

import React from "react";
import { Redirect, Router } from "@reach/router";
import Route from "../../components/Route/Route";
import { SEOReactHelmet } from "../../components/SEO/SEOReactHelmet";
import { OnboardingStateProvider } from "./OnboardingStateProvider";
import { Layout } from "./components/Layout";
import { steps } from "./steps";
import { useOnboardingTranslations } from "./translations";

import { OnboardingStateProvider as OnboardingStateProviderABTest } from "../onboarding-AB-test/OnboardingStateProvider";
import { OnboardingStateProvider as OnboardingStateProviderv2 } from "../onboarding-v2/OnboardingStateProvider";
import { Layout as LayoutABTest } from "../onboarding-AB-test/components/Layout";
import { Layout as Layoutv2 } from "../onboarding-v2/components/Layout";
import { steps as stepsABTest } from "../onboarding-AB-test/steps";
import { steps as stepsv2 } from "../onboarding-v2/steps";

import { useGoogleOptimizeVariant } from "../../hooks/useGoogleOptimizeVariant/useGoogleOptimizeVariant";

import { getLocale } from "../../utils/locale";
import { Locale } from "../../types/Locale";

const OnboardingPage = ({ pageContext }: { pageContext: { prefix: string } }) => {
  const basePath = pageContext.prefix;
  const tt = useOnboardingTranslations();

  const { variationId } = useGoogleOptimizeVariant({
    experimentId: "89NhsrhoT6yh9DnzCeP70w",
  });

  const locale = getLocale();

  return (
    <>
      <SEOReactHelmet title={tt.onboardingPage} />

      {variationId === 1 ? (
        <OnboardingStateProviderv2>
          <Layoutv2>
            <Router basepath={basePath}>
              <Redirect from="/" to="welcome" noThrow />
              {stepsv2.map((step, index) => (
                <Route key={index} component={step.component} path={step.step} />
              ))}
            </Router>
          </Layoutv2>
        </OnboardingStateProviderv2>
      ) : locale === Locale.EN ? (
        <OnboardingStateProviderABTest>
          <LayoutABTest>
            <Router basepath={basePath}>
              <Redirect from="/" to="welcome" noThrow />
              {stepsABTest.map((step, index) => (
                <Route key={index} component={step.component} path={step.step} />
              ))}
            </Router>
          </LayoutABTest>
        </OnboardingStateProviderABTest>
      ) : (
        <OnboardingStateProvider>
          <Layout>
            <Router basepath={basePath}>
              <Redirect from="/" to="welcome" noThrow />
              {steps.map((step, index) => (
                <Route key={index} component={step.component} path={step.step} />
              ))}
            </Router>
          </Layout>
        </OnboardingStateProvider>
      )}
    </>
  );
};

// ts-prune-ignore-next
export default OnboardingPage;

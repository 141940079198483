import React from "react";
import { LoadingIcon } from "../Icon/Icon";

type ButtonProps = {
  label: string;
  dataTestid?: string;
  disabled?: boolean;
  loading?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: () => void;
  className?: string;
};

export const Button = ({
  type = "button",
  label,
  onClick,
  className,
  dataTestid,
  disabled,
  loading,
}: ButtonProps) => {
  const hasTextClass = className?.includes("text-");
  const hasBgClass = className?.includes("bg-");
  return (
    <button
      tabIndex={0}
      data-testid={dataTestid}
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={`
        rounded-lg 
        px-8 py-4 
        text-base font-medium 
        ${hasTextClass ? "" : "text-white"}
        ${hasBgClass ? "" : "bg-black-100 "}
        hover:opacity-90 focus:opacity-90 
        disabled:opacity-60 disabled:cursor-not-allowed 
        transition-all duration-200 
        ${className ?? ""}
      `}
    >
      {loading ? <LoadingIcon /> : label}
    </button>
  );
};

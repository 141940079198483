import { useCallback, useMemo } from "react";
import { MeasurementSystem } from "../../types/graphql-global-types";
import { ftToCm, inToCm } from "../../utils/units";

export function useUserHeight(heightCmValue?: number) {
  const heightCm = useMemo<number>(() => (heightCmValue ? Math.floor(heightCmValue) : 0), [heightCmValue]);

  const heightFt = useMemo<number>(() => Number(Math.floor((heightCm ?? 0) / ftToCm)), [heightCm]);

  const heightIn = useMemo<number>(() => Number(Math.round(((heightCm ?? 0) % ftToCm) / inToCm)), [heightCm]);

  const calculateHeight = useCallback(
    (measurementSystem: MeasurementSystem, heightCm: number, heightFt: number, heightIn: number) => {
      if (measurementSystem === MeasurementSystem.IMPERIAL) {
        return heightFt * ftToCm + heightIn * inToCm;
      }
      return heightCm;
    },
    []
  );

  return {
    calculateHeight,
    heightCm,
    heightFt,
    heightIn,
  };
}

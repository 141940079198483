import { RouteComponentProps } from "@reach/router";
import { useActor } from "@xstate/react";
import React, { useContext, useEffect } from "react";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { Locale } from "../../../types/Locale";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useOnboardingTranslations } from "../translations";
import { OnboardingStep } from "../types";
import { navigateToNextStep } from "../utils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function HealthConditionInfoStep(_: RouteComponentProps) {
  const { locale } = useContext(pageContext);
  const tt = useOnboardingTranslations();

  const onboarding = useContext(OnboardingStateContext);
  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.HEALTH_CONDITION_INFO });
  }, [send]);

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={"We help people with metabolic health issues"} />
      <div>
        <p className="text-lg mt-0 text-center max-w-sm mx-auto px-3">
          {
            "We’re experts at helping people with type 1 or type 2 diabetes, or who need assistance lowering blood pressure."
          }
        </p>
        <p className="text-lg mt-5 text-center px-3 sm:px-1 max-w-sm mx-auto">
          Our approach involves reducing carbohydrates which can cause complications when taking certain
          medications. Therefore, we recommended checking in with a healthcare provider first.
        </p>
      </div>
      <OnboardingButton
        label={"Continue"}
        onClick={() => navigateToNextStep(OnboardingStep.HEALTH_CONDITION_INFO)}
      />
    </div>
  );
}

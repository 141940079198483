import { RouteComponentProps } from "@reach/router";
import { useActor, useSelector } from "@xstate/react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { ageToBirthYear } from "../../onboarding/onboardingPayloadToMealPlanPreferences";
import { MeasurementSystem } from "../../../types/graphql-global-types";
import { Locale } from "../../../types/Locale";
import { InputField } from "../components/InputField";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { UnitSwitcher } from "../components/UnitSwitcher";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useOnboardingTranslations } from "../translations";
import { OnboardingMachineState, OnboardingStep } from "../types";
import { useUserHeight } from "../useUserHeight";
import { changeUnit, navigateToNextStep } from "../utils";

export const birthYearSelector = (state: OnboardingMachineState) => state.context.onboardingPayload.birthYear;
export const measurementUnitSelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.measurementSystem;
export const heightCmSelector = (state: OnboardingMachineState) => state.context.onboardingPayload.heightCm;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AgeHeightStep(_: RouteComponentProps) {
  const { locale } = useContext(pageContext);
  const onboarding = useContext(OnboardingStateContext);
  const measurementUnit = useSelector(onboarding.machine!, measurementUnitSelector);
  const heightCmInit = useSelector(onboarding.machine!, heightCmSelector);

  const initialUnit = locale === Locale.SV ? MeasurementSystem.METRIC : measurementUnit;

  const [unit, setUnit] = useState<MeasurementSystem>(initialUnit ?? MeasurementSystem.IMPERIAL);
  const isImperial = useMemo(() => unit === MeasurementSystem.IMPERIAL, [unit]);

  const { heightCm, heightFt, heightIn, calculateHeight } = useUserHeight(heightCmInit);

  const [heightInCm, setHeightInCm] = useState(heightCm);
  const [heightInFeet, setHeightInFeet] = useState(heightFt);
  const [heightInInches, setHeightInInches] = useState(heightIn);

  const birthYearValue = useSelector(onboarding.machine!, birthYearSelector);
  const [, send] = useActor(onboarding.machine!);
  const tt = useOnboardingTranslations();
  const [birthYear, setBirthYear] = useState(birthYearValue ? birthYearValue.toString() : "");

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.HEIGHT_AGE });
  }, [send]);

  const isValid = useMemo(
    () => (isImperial && heightInFeet + heightInInches > 0) || (!isImperial && heightInCm > 0),
    [heightInCm, heightInFeet, heightInInches, isImperial]
  );

  const handleClick = () => {
    if (birthYear !== "") {
      const age = birthYear; // birth year is used as name in the original version
      send({
        type: "SET_PAYLOAD",
        payload: "birthYear",
        value: ageToBirthYear(new Date(), parseInt(age, 10)),
      });
    }
    if (isValid) {
      const value = calculateHeight(
        isImperial ? MeasurementSystem.IMPERIAL : MeasurementSystem.METRIC,
        heightInCm,
        heightInFeet,
        heightInInches
      );
      send({
        type: "SET_PAYLOAD",
        payload: "heightCm",
        value,
      });
    }
    navigateToNextStep(OnboardingStep.HEIGHT_AGE);
  };

  const handleChangeUnit = useCallback(() => {
    const selectedUnit = changeUnit(unit);
    setUnit(selectedUnit);
  }, [unit, setUnit]);

  useEffect(() => {
    // unit value should be MeasurementSystem type
    send({
      type: "SET_PAYLOAD",
      payload: "measurementSystem",
      value: unit,
    });
  }, [unit, send]);

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.ageHeightStep.heading} />
      <div className="w-40 mx-auto mt-5">
        <div className="flex flex-col mb-10">
          <label className="text-lg text-center mb-3">{"Age"}</label>
          <InputField
            min={1}
            max={120}
            value={birthYear}
            placeholderText={""}
            inputType="text"
            pattern="\d*"
            maxLength={3}
            onChange={(event) =>
              setBirthYear((value) => (event.target.validity.valid ? event.target.value : value))
            }
            focus={true}
          />
        </div>

        <div className="flex flex-col">
          <label className="text-lg text-center mb-3">{tt.ageHeightStep.height}</label>
          <div className="grid grid-flow-col gap-2">
            {isImperial && (
              <InputField
                value={String(Math.floor(heightInFeet))}
                min={0}
                inputType="text"
                placeholderText={isImperial ? "Ft" : "m"}
                unit="ft"
                pattern="\d*"
                maxLength={2}
                onChange={(event) =>
                  setHeightInFeet(event.target.validity.valid ? Number(event.target.value) : heightInFeet)
                }
              />
            )}
            <InputField
              value={String(Math.floor(isImperial ? heightInInches : heightInCm))}
              min={0}
              inputType="text"
              placeholderText={isImperial ? "In" : "cm"}
              pattern="\d*"
              unit={isImperial ? "in" : "cm"}
              maxLength={isImperial ? 2 : 3}
              onChange={(event) => {
                if (isImperial) {
                  const val = event.target.validity.valid ? Number(event.target.value) : heightInInches;
                  setHeightInInches(val);
                } else {
                  const val = event.target.validity.valid ? Number(event.target.value) : heightInCm;
                  setHeightInCm(val);
                }
              }}
            />
          </div>
        </div>
        {locale !== Locale.SV && <UnitSwitcher switchType="length" onChange={handleChangeUnit} unit={unit} />}
      </div>

      <OnboardingButton label={tt.buttonNext} onClick={handleClick} />
    </div>
  );
}

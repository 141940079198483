import React, { useContext, useEffect } from "react";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { SingleChoiceButton } from "../components/SingleChoiceButtton";
import { OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { OnboardingTranslation, useOnboardingTranslations } from "../translations";
import { useActor, useSelector } from "@xstate/react";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { Ranking } from "../../../types/graphql-global-types";
import { navigateToNextStep } from "../utils";

export const cookingSkillSelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.cookingSkill;

export function CookingSkillsStep({ onDone }: OnboardingStepProps) {
  const onboarding = useContext(OnboardingStateContext);
  const cookingSkill = useSelector(onboarding.machine!, cookingSkillSelector);
  const tt = useOnboardingTranslations();
  const cookingSkillOptions = getOptions(tt);

  const [, send] = useActor(onboarding.machine!);

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.COOKING_SKILL });
  }, [send]);

  const handleChange = (value: Ranking) => {
    send({ type: "SET_PAYLOAD", payload: "cookingSkill", value });
    if (onDone) {
      onDone?.();
    } else {
      navigateToNextStep(OnboardingStep.COOKING_SKILL);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.cookingSkillsStep.question} />

      {cookingSkillOptions.map(({ option, label, explanation }) => (
        <SingleChoiceButton
          key={option}
          value={option}
          textLabel={label}
          name="cookingSkill"
          explanation={explanation}
          onChange={(event) => handleChange(event.target.value as Ranking)}
          isActive={cookingSkill === option}
        />
      ))}
    </div>
  );
}

export function getOptions(
  tt: OnboardingTranslation
): Array<{ option: Ranking; label: string; explanation?: string }> {
  return [
    {
      option: Ranking.LOW,
      label: tt.cookingSkillsStep.answerBeginner,
      explanation: tt.cookingSkillsStep.answerBeginnerExplanation,
    },
    {
      option: Ranking.MEDIUM,
      label: tt.cookingSkillsStep.answerAverage,
      explanation: tt.cookingSkillsStep.answerAverageExplanation,
    },
    {
      option: Ranking.HIGH,
      label: tt.cookingSkillsStep.answerMasterChef,
      explanation: tt.cookingSkillsStep.answerMasterChefExplanation,
    },
  ];
}

import React from "react";

export default function VisibleIcon(props: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 4C4 4 1 12 1 12C1 12 4 20 12 20C20 20 23 12 23 12C23 12 20 4 12 4ZM12 6C17.3 6 19.9008 10.3 20.8008 12C19.9008 13.7 17.3 18 12 18C6.7 18 4.09922 13.7 3.19922 12C4.09922 10.3 6.8 6 12 6ZM12 8C9.8 8 8 9.8 8 12C8 14.2 9.8 16 12 16C14.2 16 16 14.2 16 12C16 9.8 14.2 8 12 8ZM12 10C13.1 10 14 10.9 14 12C14 13.1 13.1 14 12 14C10.9 14 10 13.1 10 12C10 10.9 10.9 10 12 10Z"
        fill="black"
      />
    </svg>
  );
}
